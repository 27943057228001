import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class TestimonialSlides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData,
      index: props.index
    }
  }

  render() {
    const index = this.state.index;
    return (
      <div key={index} className={` ${"pacakage-deatil-listing "} ${(index > 2 && index < 6) ? 'white-text' : 'white-text'}`}
      >
        <Link target="_blank" to={"/travel-packages/" + this.state.slideData.slug + "?categories[]="+this.state.slideData.slug}>
          <figure><img src={this.state.slideData.landscape_image} alt="" /></figure>
          <figcaption>
            {
              this.state.slideData.sub_title === "0" ? null :
                <div className="top-content"><span className="package-title">{this.state.slideData.sub_title}</span>
                </div>
            }
            <div className="bottom-content">
              <h4>{this.state.slideData.title}</h4>
              {/* <p className={` ${"package"}${index + 1} `}>{this.state.slideData.destination_cnt > 20 ? "20+" : this.state.slideData.destination_cnt} Destinations</p> */}
              <p className={` ${"package4"} `}>{this.state.slideData.destination_cnt > 20 ? "20+" : this.state.slideData.destination_cnt} Destinations</p>
            </div>
          </figcaption>
        </Link>
      </div>
    );
  }
}