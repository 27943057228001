import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import AuthLink from '../../AuthLink';
import AuthModals from '../../AuthModals';
import MenuHeaderNavigation from './MenuHeaderNavigation';
import MenuHeaderDestinationNavigation from './MenuHeaderDestinationNavigation';
import ThemeMenu from './ThemeMenu';
import { connect } from 'react-redux';
import LoginModal from '../../LoginModal';
import CabOptions from './CabOptions';
import SignupModal from '../../SignupModal';
import MobileNavigation from './MobileNavigation';

class HeaderNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null,
      dropdownType: null,
      chkNavigation: props.chkNavigation,
      status: props.status
    };
    this.toggle = this.toggle.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showSignupModal = this.showSignupModal.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', (e) => {
      if (e.target.className !== 'menu-item' && this.state.activeMenu !== null) {
        this.setState({
          activeMenu: null
        });
      }
    })
  }

  componentDidUpdate() {
    
  }

  toggle(e, activeMenu) {
    e.preventDefault();
    this.setState({
      activeMenu
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showModal() {
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  showSignupModal() {
    window.getFooter().setState({
      renderElement: <SignupModal onHide={this.hideModal.bind(this)} />
    });
  }

  render() {
    const { auth, user, src } = this.props
    const { activeMenu } = this.state;
    console.log(this.state.chkNavigation, 'ooooooooodesktop')

    // console.log(this.state.chkNavigation, "this.state.chkNavigation.holiday_packages_menuhis.state.chkNavigation.holiday_packages_menuhis.state.chkNavigation.holiday_packages_menu")

    return (
      <nav>
        <div className="navigation-top dsktop-navigation">
          <ul>
            <li className={`has-submenu h-package ${activeMenu == 'holiday' ? "active-menu active" : ""}`}>
              <a className="menu-item" href="javascript:void(0)" onMouseOver={(e) => this.toggle(e, 'holiday')}>Holiday Packages</a>
              <span className="submenu-arrow"></span>
              {activeMenu == 'holiday' && <MenuHeaderNavigation dropdownType={this.state.dropdownType} />}
            </li>
            <li className={`has-submenu d-package ${activeMenu == 'destination' ? "active-menu active" : ""}`}>
              <a className="menu-item" href="javascript:void(0)" onMouseOver={(e) => this.toggle(e, 'destination')}>Destination Guides</a>
              <span className="submenu-arrow"></span>
              {activeMenu == 'destination' && <MenuHeaderDestinationNavigation dropdownType={this.state.dropdownType} />}
            </li>
            {/* <li className={`has-submenu theme-package ${activeMenu == 'theme' ? "active-menu active" : ""}`}>
              <a className="menu-item" href="javascript:void(0)" onMouseOver={(e) => this.toggle(e, 'theme')}>Themes</a>
              <span className="submenu-arrow"></span>
              {activeMenu == 'theme' && <ThemeMenu dropdownType={this.state.dropdownType} />}
            </li> */}
            <li>
              <AuthLink />
            </li>
          </ul>
        </div>
        <AuthModals hideModal={this.hideModal} />

        <MobileNavigation />
      </nav>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    auth: state.Auth.auth,
    user: { ...state.Auth.user },
    src: state.Auth.user.profile_image,
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status
  }
}

export default connect(mapStatesToProps)(HeaderNavigation);