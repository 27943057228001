import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { fetchBasicInfo, resetQuoteRequestForm } from './actions/customPackage';
import QueryForm from './components/partials/request/QueryForm';

class EnquiryRequestModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      basicinfo: {},
      packageId: typeof props.packageId !== 'undefined' ? props.packageId : 0,
    };

    this.hide = this.hide.bind(this);
  }

  componentWillMount() {
    fetchBasicInfo().then(response => {
      this.setState({
        basicinfo: response.data.payload
      });
    });
  }

  // componentWillUnmount() {
  //   this.props.resetQuoteRequestForm();
  // }

  // updateStep(step) {
  //   this.setState({
  //     step
  //   });
  // }

  hide() {
    this.props.toggle();
  }

  render() {
    const { step, basicinfo, packageId } = this.state;

    // console.log(basicinfo.destinations, "basicinfo.destinations");

    return (
      <div className="cutsomize-pacakage-block enquiry">
        <div className="modal-block">
          <Modal
            id="customize-modal"
            show={true}
            onHide={this.hide}
            body={
              <div>
                <QueryForm index={this.props.index}  realOnly={this.props.realOnly}
                  destinations={basicinfo.destinations}  packageId={packageId} data={this.props.data} samePackage={this.props.samePackage} hideForm={this.hide} />
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default connect(null, { resetQuoteRequestForm })(EnquiryRequestModal);