// Vikas
import React, { Component } from 'react';
import JSDOM from 'jsdom';

class PackageItineraryItem extends Component {
  constructor(props) {
    super(props);
    this.bind = this.bind.bind(this);
    this.state = {
      //readMoreValue: "more"
    }
  }

  componentDidMount() {
    this.toggleReadMore(null, this.props.obj.description);
    window.addEventListener('load', this.bind);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bind();
    }
  }

  bind() {
    window.$$(this.refs.itinerarySlider).bxSlider({
      mode: 'fade',
      auto: true,
      controls: true,
      preloadImages: 'visible',
      autoDelay: 5
    });
  }

  toggleReadMore = (e, htmlString) => {
    if (e) {
      e.preventDefault();
    }
    //Check if there is need to show read more

    let str = htmlString.replace(/<[^>]*>/g, '');
    let showReadMore = true;

    if (str.length <= 250) {
      showReadMore = false;
    }

    let textContent;
    if (this.state.readMore) {
      textContent = htmlString;
    } else {
      textContent = htmlString.replace(/<[^>]*>/g, '').substring(0, 250);
    }

    // Display the result
    console.log(textContent);
    this.setState({
      text: textContent,
      readMore: !this.state.readMore,
      showReadMore: showReadMore
    });

  }

  render() {
    const { obj } = this.props;
    console.log('kkkkkkk', this.state.showReadMore)

    return (
      <div className="ltierary-detail-content">
        <div className="days-ltinerary">
          <div className="no-days">
            <label>{obj.day}</label>
            <span>Day</span>
          </div>
          <div className="detail-days-ltinerary">
            <div className="block-days-in">
              <div className="head-ltinerary--day">
                <h3>{obj.title}, {obj.name}</h3>
                {
                   obj.gallery && obj.gallery.length > 0 && 
                   <div className="slider-status">
                  <ul ref="itinerarySlider" className="bxslider-status">
                    {
                      obj.gallery && obj.gallery.length > 0 && obj.gallery.map((gal, gndex) => (

                        <li key={gndex}>
                          <div className="days-slider">Day {obj.day}</div>
                          <div className="station-name">{obj.title}</div>
                          <img src={gal} alt="" />
                        </li>

                      ))
                    }


                  </ul>
                </div>
                }
                
                <div className='inner-container'>
                <p dangerouslySetInnerHTML={{ __html: obj.description }} />
                  {/* <p dangerouslySetInnerHTML={{ __html: this.state.text }} />
                  {
                    this.state.showReadMore
                      ?
                      <a href="javascript:;" className="readmore-link-blue" onClick={(e) => this.toggleReadMore(e, obj.description)}>{this.state.readMore ? "Read more" : "Read less"}</a>
                      :
                      <></>
                  } */}

                  {/* <p dangerouslySetInnerHTML={{ __html: obj.description }} /> */}
                  <div className='ltinerery-fields-wrpr'>
                    <div className="block-ltinerery-fields">
                      {/* <label>Activities</label> */}
                      <ul className="activities-fields">
                        {
                          obj.activities && obj.activities.length > 0 && obj.activities.map((chk, endex) => {
                            if(!chk){
                              return null;
                            }
                            return (
                              <li key={endex}>{chk}</li>
                            )
                          })
                        }
                      </ul>
                    </div>
                    {/* <div className="block-ltinerery-fields">
                    <label>Total Duration</label>
                    <div className="duration-ltinerery">{obj.duration}</div>
                  </div> */}
                    <div className="block-ltinerery-fields">
                      <label>Locations</label>
                      <div className="ltinerary-text-content">
                        <ul>
                          {
                            obj.locations && obj.locations.length > 0 && obj.locations.map((loc, lndex) => (
                              <li key={lndex}>{loc}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                    {
                      obj.hotels && obj.hotels.length > 0 &&
                      <div className="block-ltinerery-fields">
                      <label>Hotel</label>
                      <div className="ltinerary-text-content">
                        <ul className='ltinerary-hotel-list'>
                          {
                            obj.hotels && obj.hotels.length > 0 && obj.hotels.map((hotel, lndex) => (
                              <li key={lndex}>{hotel.name}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                    }
                    
                    {/* <div className="block-ltinerery-fields">
                    <label>Optional Activities<span className="info-warning">Extra Charges</span></label>
                    <div className="ltinerary-text-content">
                      <ul>
                        {
                          obj.optional_activities && obj.optional_activities.length > 0 && obj.optional_activities.map((act, andex) => (
                            <li key={andex}>{act}</li>
                          ))
                        }
                      </ul>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackageItineraryItem;