import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import { connect } from 'react-redux';
import { currencyFormat } from '../../constants';
import { featuredPackagesList, featuredPackagesListSuccess } from '../../actions/packages';


class BestSellingPackage extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      featuredPackages: props.featuredPackages,
      totalPackages: props.totalPackages,
      status: props.status
    };
  }

  componentWillMount() {
    this.props.featuredPackagesList().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.featuredPackagesListSuccess(response.value.data.payload, response.value.data.totalpackages);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'best-selling-packages') {
      this.setState({
        featuredPackages: nextProps.featuredPackages,
        totalPackages: nextProps.totalPackages,
        status: nextProps.status
      });


    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status == this.state.status)
      return;
    window.addEventListener('load', this.bindSlider);
    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  bindSlider() {
    const $ = window.$$;
    if ($(window).width() > 991) {
      $(this.refs.packageSlides).owlCarousel({
        loop: false,
        margin: 15,
        pagination: false,
        nav: true,
        navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
        responsive: {
          0: {
            items: 1.2
          },
          400: {
            items: 1.4
          },
          500: {
            items: 2.2
          },
          768: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      });
    }
  }

  render() {
    return (
      <section className="selling-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="selling-inner-block">
                <div className="heading-block">
                  <h3 className="title">Best Selling Packages</h3>
                  <p className="sub-title">Here are the best packages which are trending on Travalot </p>
                </div>
                <div className="gallery-block">
                  <div className="owl-carousel owl-theme package-slides" ref="packageSlides">

                    {
                      this.state.featuredPackages && this.state.featuredPackages.length > 0 &&
                      this.state.featuredPackages.map((obj, index) => (

                        <div className="item" key={index}>
                          <div className="carousel-inner">
                            <Link target="_blank" to={"/package/detail/" + obj.slug}>
                              <figure><img src={obj.landscape_image} alt="" /></figure>
                              <figcaption className="package-detail-block">
                                <ul>
                                  <li className="tag-btn">
                                    <i className="icons"><img src="/images/ios-trending-up.svg" /></i>
                                    <span>Trending</span>
                                  </li>
                                  <li>{obj.tags}</li>
                                </ul>
                                <h5>{obj.package_name}</h5>
                                <p>{obj.title}</p>
                                <label className="price-label">Starts from {currencyFormat(obj.minimumprice, 'INR')}/- </label>
                              </figcaption>
                            </Link>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="view-all pos-top">
                  <Link className="n-blue-btn" to="/travel-packages">Show ALL ({this.state.totalPackages > 30 ? "30+" : this.state.totalPackages})<img src="/images/button_arrow.svg" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}


const mapStatesToProps = (state, ownProps) => {
  return {
    featuredPackages: [...state.packages.featuredpackages],
    totalPackages: state.packages.totalpackages,
    status: state.packages.status,
    compName: state.packages.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    featuredPackagesList: () => {
      return dispatch(featuredPackagesList());
    },
    featuredPackagesListSuccess: (payload, totalpackages) => {
      dispatch(featuredPackagesListSuccess(payload, totalpackages));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(BestSellingPackage);