import React, { Component } from 'react';

export default class HowItWorks extends Component {
   	render() {
      	return (
      		<section className="holiday-video-block" id="steps-video">
	            <div className="container">
	               <div className="row">
	                  <div className="col-lg-6 col-md-12 col-sm-12 justify-content-center align-self-center">
	                     <div className="video-left-block">
	                        <label>HOW IT WORKS</label>
	                        <h4>Customize &
	                           Book Amazing
	                           Holiday Packages
	                        </h4>
	                        <p className="four-step-heading">in just <a href="javascript:;">4 Simple Steps</a></p>
	                        
	                        <ul className="video-listing">
	                           <li>
	                              <span className="steps-number">1</span>
	                              <h5>Explore Destinations</h5>
	                              <p>Choose from 60+ Popular destinations with tailor made holiday packages</p>
	                           </li>
	                           <li>
	                              <span className="steps-number">2</span>
	                              <h5>Check Availability</h5>
	                              <p>Choose the best time to make your trip comfortable and memorable</p>
	                           </li>
	                           <li>
	                              <span className="steps-number">3</span>
	                              <h5>Book Online</h5>
	                              <p>Choose the best time to make your trip comfortable and memorable</p>
	                           </li>
	                           <li>
	                              <span className="steps-number">4</span>
	                              <h5>Get Ready to Fly</h5>
	                              <p>Add wings to your dreams, It’s time to reach your destination</p>
	                              
	                           </li>
	                        </ul>
	                     </div>
	                  </div>
	                  <div className="col-lg-6 col-md-12 col-sm-12 justify-content-center align-self-center">
	                     <div className="video-block ">
	                        <button className="play-btn mob-view">
                                <svg version="1.1" id="play" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px" height="100px" width="100px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" white-space="preserve">
                                    <path className="stroke-solid" fill="none" stroke="white" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
                   C97.3,23.7,75.7,2.3,49.9,2.5" />
                                    <path className="stroke-dotted" fill="none" stroke="white" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
                   C97.3,23.7,75.7,2.3,49.9,2.5" />
                                    <path className="icon" fill="white" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z" />
                                </svg>
                                <span className="watch-text d-md-block d-lg-none">Watch Now</span>
                            </button>
	                     </div>
	                  </div>
	               </div>
	            </div>
	        </section>
      	);
	}
}