// Vikas
import React, { Component } from "react";
import moment from "moment";

export default class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      single: typeof props.single !== "undefined" ? props.single : false,
      monthPicker:
        typeof props.monthPicker !== "undefined" ? props.monthPicker : false,
      selectedValue:
        typeof props.selectedValue !== "undefined" ? props.selectedValue : "",
      disabled: typeof props.disabled !== "undefined" ? props.disabled : false,
    };
    this.bind = this.bind.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.bind);

    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      this.bind();
    }
  }

  bind() {
    const { single, selectedValue } = this.state;
    const $ = window.$$;
    const elem = this.refs.daterange;
    let selectedDate = null;
    let formattedDate = null;
    const settings = {
      singleDatePicker: single,
      minDate: new Date(),
      opens: "left",
      locale: {
        format: "DD MMM",
      },
    };
    if (selectedValue != "") {
      settings.startDate = moment(selectedValue, "YYYY-MM-DD");
      $(elem).html(settings.startDate.format("DD/MM/YYYY"));
    }

    $(elem).daterangepicker(settings, (start, end, label) => {
      if (single) {
        formattedDate = start.format("DD/MM/YYYY");
        selectedDate = start.format("YYYY-MM-DD");
      } else {
        formattedDate = start.format("DD MMM") + " - " + end.format("DD MMM");
        selectedDate =
          start.format("YYYY-MM-DD") + " - " + end.format("YYYY-MM-DD");
      }

      $(elem).html(formattedDate);
      this.setState({
        selectedValue: selectedDate,
      });
      this.props.onHandleChange(selectedDate);
    });
  }

  render() {
    const { disabled } = this.state;
    return (
      <div className="datepicker-block">
        {this.props.fromContactFrom ? (
          <button
            disabled={disabled}
            ref="daterange"
            name="daterange"
            className="datepicker-btn"
            style={{ color: "grey" }}
          >
            Choose Date of Travel
          </button>
        ) : (
          <button
            disabled={disabled}
            ref="daterange"
            name="daterange"
            className="datepicker-btn"
          >
            Choose Date
          </button>
        )}
        {/* <button disabled={disabled} ref="daterange" name="daterange" className="datepicker-btn">Choose Date</button> */}
        <div className="drp-buttons">
          <span className="drp-selected" />
        </div>
      </div>
    );
  }
}
