import React, { Component } from "react";
import { connect } from "react-redux";

// import Sidebar from "./components/partials/request/Sidebar";
import ContactForm from "./ContactForm";
import Modal from "../../../Modal";
import Sidebar from "./Sidebar";
// import { resetQuoteRequestForm } from "./actions/customPackage";

class ContactDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicinfo: {},
      packageId: typeof props.packageId !== "undefined" ? props.packageId : 0,
      step: 1,
    };

    this.hide = this.hide.bind(this);
    // this.updateStep = this.updateStep.bind(this);
  }

  //   componentWillMount() {
  //     fetchBasicInfo().then((response) => {
  //       this.setState({
  //         basicinfo: response.data.payload,
  //       });
  //     });
  //   }

  //   componentWillUnmount() {
  //     this.props.resetQuoteRequestForm();
  //   }

  //   updateStep(step) {
  //     this.setState({
  //       step,
  //     });
  //   }

  hide() {
    this.props.toggle();
  }

  render() {
    const { step, basicinfo, packageId } = this.state;

    return (
      <div className="cutsomize-pacakage-block" style={{ background: "red" }}>
        <div className="modal-block">
          <Modal
            id="contact-detail-modal"
            show={true}
            onHide={this.hide}
            body={
              <div>
                {step == 1 && (
                  <ContactForm
                    index={this.props.index}
                    theme_id={this.props.theme_id}
                    realOnly={this.props.realOnly}
                    destinations={basicinfo.destinations}
                    themes={basicinfo.themes}
                    updateStep={this.updateStep}
                    packageId={packageId}
                    data={this.props.data}
                    samePackage={this.props.samePackage}
                  />
                )}
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default connect(null)(ContactDetail);
