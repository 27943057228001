import React, { Component } from 'react';

export default class DestinationGuideNavigation extends Component {
  render() {
    return (
      <div className="nav-listing">
        <button className="dots-hamburger1" onClick = {() => {
          window.$$(".dots-hamburger1").toggleClass('active-hamburger');
          window.$$(".dots-hamburger1").parent('.nav-listing').find('ul').toggleClass('active');
        }}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul>
          <li className="active"><a href="#transport-guide">Overview</a></li>
          <li><a href="#best-visit">Best time to visit</a></li>
          <li><a href="#how-teach">How to reach</a></li>
          <li><a href="#popularplaces">Popular places</a></li>
          <li><a href="#packages-view">Packages</a></li>
        </ul>
      </div>
    );
  }
}