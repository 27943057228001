// Vikas - 08-01-2018
import React, { Component } from 'react';

export default class PackageHotels extends Component {
	constructor(props){
        super(props);
		
        this.state = {
            package_hotels: props.package_hotels,
			hotels: []
        };
		
    }

	componentDidMount(){
		this.fixHotelData(this.state.package_hotels);
	}

    componentWillReceiveProps(nextProps){
    	if(JSON.stringify(nextProps.package_hotels) != JSON.stringify(this.state.package_hotels)){
			
    		this.setState({
    			package_hotels: nextProps.package_hotels,
            	ratings: nextProps.ratings
    		});
			this.fixHotelData(nextProps.package_hotels);
    	}
    }

	fixHotelData = (hotelData) => {
		if(!hotelData.length){
			return;
		}
		let temp = "";
		let lastIndex = 0;
		let data = [];
		hotelData.forEach((obj, index) => {
			if(obj.name != temp){
				obj = Object.assign(obj, {startDay: obj.day, endDay: obj.day});
				data.push(obj);
				
			}else{
				data[data.length - 1] = Object.assign(data[data.length - 1], {endDay: obj.day})
				
			}
			temp = obj.name;
		});
		this.setState({hotels: data})

	}

	render() {
		
		return (
    		<section className="hotel-customize scroll-block" id="hotel-tab">
		        <div className="container">
		            <div className="row">
		              	<div className="col-8">
		              		<div className="hotel-left-section">
		                  		<h2 className="heading-section">Hotels</h2>
		                  		{
					              	this.state.hotels.length > 0 
					              	? 
					              	this.state.hotels.map((obj, index) => {

									return(
					                	<div className="hotel--main-left">
				                    		<div className="hotel-days">
				                      			<span>From</span>
				                      			
												{
													obj.startDay != obj.endDay
													?
													<label>Day { obj.startDay } - { obj.endDay}</label>
													:
													<label>Day { obj.startDay }</label>

												}
				                    		</div>
				                    		<div className="hotel-detail">
				                      			<figure>
				                        			<img src={ obj.imagepath } alt={ obj.name } />
				                      			</figure>
					                      		<div className="hotel-detail-right">
					                        		<div className="rating"> 
					                          			<ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
					                          			<span>{ obj.star_rating }.0</span>
					                        		</div>
					                        		{/* <label className="reviews-hotel">62 Reviews</label> */}
					                        		<div className="hotel-caption">
					                          			<h4>{ obj.name }</h4>
					                          			<p>{ obj.address }</p>
					                        		</div>
					                        		<div className="hotel-action-btns">
					                        			<a className="map-btn" href={"https://www.google.com/maps/search/?api=1&query=" + obj.latitude +","+ obj.longitude } target="_blank"><span>VIEW ON MAPS</span></a>
					                          			<a href="javascript:void(0);" className="n-blue-btn">VIEW DETAILS</a>
					                        		</div>
					                      		</div>
					                    	</div>
					                  	</div>
						            )})
						            :
						            <div className="hotel--main-left">
						            	No hotel found!
						            </div>
					            }
		                  		
			                </div>
			            </div>


			            <div className="col-4">
			                <div className="customize-hotel-feature">
			                  	<div className="subheading-block">
			                    	<h3>Want to change hotel?</h3>
			                    	<a href="javascript:void(0);">Customize Package</a>
			                  	</div>
			                  	<div className="discription-hotel">
			                    	<ul>
			                      		<li>In the Peak season, Hotels may be different, but you will get the same amenities.</li>
			                      		<li>Breakfast & Dinner will be provided inside the hotel</li>
			                      		<li>Rooms are customized according to the preferences of the honeymooners.</li>
			                    	</ul>
			                  	</div>
			                </div>
			            </div>
			        </div>
			    </div>
			</section>
    	);
    }
}