import React, { Component } from 'react';
import InnerHeaderLogo from './InnerHeaderLogo';
import InnerHeaderSearch from './InnerHeaderSearch';
import HeaderNavigation from './HeaderNavigation';

export default class InnerFullHeader extends Component {
	render() {
    	return (

			<header className="header-main inner-header transparent-bg">
			    <div className="container-fluid">
			    	<InnerHeaderLogo />
			       	<button className="nav-hamburger" onClick={(event) => {
						event.stopPropagation();
						if (window.$$('body').hasClass('nav-active')) {
							window.$$('body').removeClass('nav-active');
						} else {
							window.$$('body').addClass('nav-active');
						}
					}}><span></span><span></span></button>
			       	<InnerHeaderSearch />
			       	<HeaderNavigation />
			    </div>
			</header>
    	);
	}
}