import React, { Component } from 'react';

export default class WhyTravelAtlas extends Component {
  render() {
    return (
      <section className="w-travel-block text-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="w-travel-heading">
                <h3>Why Choose Travalot?</h3>
                {/* <p>Travalot is Fastest Growing Holiday Marketplace which is easy, fast, secure & reliable</p> */}
                <p>Travalot is a one-stop solution for all your travel planning needs. From expertly crafted itineraries to impeccable customer service, we make your travel dreams a seamless reality.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                {/* <figure> <img src="/images/ease_of_use.svg" alt="" /></figure> */}
                <figcaption>
                  <h5>Craft Your Dream Trip</h5>
                  <p>We don't just book vacations, we design personalized itineraries tailored to your travel style.</p>
                </figcaption>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                {/* <figure> <img src="/images/fast.svg" alt="" /></figure> */}
                <figcaption>
                  <h5>Expert Travel Planners</h5>
                  <p>Our travel experts match you with the perfect destinations and experiences to ignite your wanderlust.</p>
                </figcaption>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                {/* <figure> <img src="/images/secure.svg" alt="" /></figure> */}
                <figcaption>
                  <h5>Stress-Free Planning</h5>
                  <p>Relax, we handle the details. We create your dream escape from start to finish.</p>
                </figcaption>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                {/* <figure> <img src="/images/support.svg" alt="" /></figure> */}
                <figcaption>
                  <h5>Unforgettable Memories</h5>
                  <p>We are passionate about travel and committed to making your trip more than just a vacation.</p>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}