import React, { Component } from 'react';

export default class SocialNetworkLink extends Component {
	render() {
		return(
            <ul className="social-icons">
           		<li>
              		<a href="https://www.facebook.com/travalotofficial?mibextid=LQQJ4d" target="_blank"> <img src="/images/ic_facebook.svg" alt=""/></a>
           		</li>
           		<li>
              		<a href="https://www.instagram.com/travalotofficial?igsh=MWNtbWYxMDJ4aXA5Zg==" target="_blank"><ion-icon name="logo-instagram"></ion-icon></a>
           		</li>
           		<li>
              		<a href="javascript:;"><ion-icon name="logo-twitter"></ion-icon></a>
           		</li>
        	</ul>
		);
	}
}