import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingButtonStyle1 from '../../BookingButtonStyle1';

export default class PackagePolicy extends Component {
  render() {
    return (
      <>
      {/* <section className="thinks-mind">
        <div className="container">
          <div className="head-2-ui">
            <h2 className="heading-section">Keep these things in mind</h2>
            <p>Here are the things you have to read carefully. it’s all about cancellation policy and other terms for this package</p>
          </div>
          <div className="ui-block-list">
            <div className="row">
              <div className="col-4">
                <div className="ui-block-content">
                  <h4>Cancellation policy</h4>
                  <p>Any package purchased from TravelAtlas can be canceled and fully refunded within 24 hours of purchase.<Link to="/cancellation-policy" className="show-more-link">See cancellation policy</Link></p>
                </div>
              </div>
              <div className="col-4">
                <div className="ui-block-content">
                  <h4>Room Sharing</h4>
                  <p>Total amount is showing in this package is per person on the basis of Twin sharing. But you can customize it completely.<BookingButtonStyle1 customClass="show-more-link" label="Customize Now" /></p>
                </div>
              </div>
              <div className="col-4">
                <div className="ui-block-content">
                  <h4>Government ID Card</h4>
                  <p>You’ll need to take a Photo ID Card for all the respective persons. It is required for Hotel Check ins and also for security purpose.</p>
                </div>
              </div>
              <div className="col-4">
                <div className="ui-block-content">
                  <h4>Activities Warning</h4>
                  <p>If you’re heart patient or any facing any medical problems please do not opt for the activities. It can be dangerous for you. </p>
                </div>
              </div>
              <div className="col-4">
                <div className="ui-block-content">
                  <h4>Terms & Conditions</h4>
                  <p>For more information on, Policies, Timings, Contact details, Emergency contact details & much more info you can Check our <Link to="/terms-condition" className="show-more-link">Terms & Conditions</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </>
    );
  }
}