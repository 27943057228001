export const ROUTES = {
  HOME: {
    URL: "/"
  },
  THEME: {
    LIST: {
      URL: "/theme/:index",
      PARENT: "InnerLayout"
    },
    LISTING: {
      URL: "/themelisting",
      PARENT: "InnerLayout"
    }
  },
  DESTINATION: {
    LIST: {
      URL: "/destination",
      PARENT: "InnerLayout"
    }
  },
  BLOG: {
    LIST: {
      URL: "/blog",
      PARENT: "BlogLayout"
    },
    DETAIL: {
      URL: "/blog/:index",
      PARENT: "BlogLayout"
    }
  },
  TRAVELSTORY: {
    LIST: {
      URL: "/travelstories",
      PARENT: "BlogLayout"
    },
    DETAIL: {
      URL: "/travelstories/:index",
      PARENT: "BlogLayout"
    }
  },
  TRAVELPACKAGES: {
    LIST: {
      URL: "/travel-packages/:slug?",
      PARENT: "InnerLayout"
    }
  },
  DESTINATIONPACKAGE: {
    LIST: {
      URL: "/destinationpackage/:index",
      PARENT: "InnerLayout"
    }
  },
  DESTINATIONGUIDE: {
    URL: "/destinationguide/:index",
    PARENT: "InnerFullHeaderLayout"
  },
  PACKAGE: {
    DETAIL: {
      URL: "/package/detail/:index",
      PARENT: "InnerLayout"
    }
  },
  REQUESTQUOTEDETAIL: {
    URL: "/trip_request/:index",
    PARENT: "LeftBigRightLayout"
  },
  REQUESTINVOICEVIEW: {
    URL: "/trip_invoice/:index",
    PARENT: "LeftBigRightLayout"
  },
  DASHBOARD: {
    LIST: {
      URL: "/dashboard",
      PARENT: "InnerLayout"
    },
    QUOTESLIST: {
      URL: "/dashboard/quoteslist",
      PARENT: "InnerLayout"
    },
    HOLIDAYPREFERENCES: {
      URL: "/dashboard/holidaypreferences",
      PARENT: "InnerLayout"
    },
    SAVEDPACKAGES: {
      URL: "/dashboard/savedpackages",
      PARENT: "InnerLayout"
    },
    PROFILE: {
      URL: "/dashboard/profile",
      PARENT: "InnerLayout"
    },
    EDITPROFILE: {
      URL: "/dashboard/editprofile",
      PARENT: "InnerLayout"
    },
    SETPASSWORD: {
      URL: "/dashboard/setpassword",
      PARENT: "InnerLayout"
    }
  },
  ABOUTUS: {
    URL: "/about-us",
    PARENT: "InnerLayout"
  },
  TERMS: {
    URL: "/terms-condition",
    PARENT: "InnerLayout"
  },
  PRIVACYPOLICY: {
    URL: "/privacy-policy",
    PARENT: "InnerLayout"
  },
  CONTACTUS: {
    URL: "/contact-us",
    PARENT: "InnerLayout"
  },
  HELPSUPPORT: {
    URL: "/help-support",
    PARENT: "InnerLayout"
  },
  CANCELLATION: {
    URL: "/cancellation-policy",
    PARENT: "InnerLayout"
  },
  OURPARTNERS: {
    URL: "/our-partners",
    PARENT: "InnerLayout"
  },
  CAREER: {
    URL: "/career",
    PARENT: "InnerLayout"
  },
  NOTIFICATION: {
    URL: "/notifications",
    PARENT: "InnerLayout"
  },
  TRAVELOTFAQ: {
    URL: "/faqs",
    PARENT: "InnerLayout"
  },
  RESETPASSWORD: {
    URL: "/resetpassword/:index",
    PARENT: "InnerLayout"
  }


};