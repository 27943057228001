import React, { Component } from 'react';
import BlogHeaderLogo from './BlogHeaderLogo';
import InnerHeaderSearch from './InnerHeaderSearch';
import BlogHeaderNavigation from './BlogHeaderNavigation';

export default class InnerFullHeader extends Component {
	render() {
    	return (
			<header className="header-main inner-header transparent-bg blog-full-header">
                <div className="container-fluid">
                  	<BlogHeaderLogo />
                  	<button className="nav-hamburger" onClick={(event) => {
						event.stopPropagation();
						if (window.$$('body').hasClass('nav-active')) {
							window.$$('body').removeClass('nav-active');
						} else {
							window.$$('body').addClass('nav-active');
						}
					}}><span></span><span></span></button>
                  	<InnerHeaderSearch />
                  	<BlogHeaderNavigation />
                </div>
           </header>
    	);
	}
}

