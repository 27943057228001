import React, { Component } from 'react';
import { connect } from 'react-redux';
import DestinationGuidePopularPackageSlide from './DestinationGuidePopularPackageSlide';
import { destinationGuidePopularPack } from '../../actions/destinationGuide';

class DestinationGuidePopularPackages extends Component {
  constructor(props){
      super(props);
      this.state = {
        popularpackage: [],
        slug: props.slug
      };

      this.bindSlider = this.bindSlider.bind(this);
      this.fetchRecords = this.fetchRecords.bind(this);
  }

  componentDidMount(){
      this.fetchRecords(this.state.slug);
  }

  componentDidUpdate(){
      window.addEventListener('load', this.bindSlider);

      if(document.readyState === "complete" || document.readyState === "interactive"){
        this.bindSlider();
      }
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.slug != this.state.slug){
          this.fetchRecords(nextProps.slug);
      }
  }

  fetchRecords(slug){
    this.props.destinationGuidePopularPack({to_destination: slug}).then((response) => {
      if(response.value.data.result === 'success'){
        this.setState({
          popularpackage: response.value.data.payload,
          slug
        });
      }
    });
  }

  bindSlider(){
      const $ = window.$$;
      if($(window).width() > 991){
        $(this.refs.packagesSlider).owlCarousel({
          loop:false,
          margin:15,
          pagination: false,
          nav:true,
          navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
          responsive:{
              0:{
                  items:1.2
              },
              400:{
                items:1.4
            },
              500:{
                items:2.2
              },
              768:{
                  items:3
              },
              1000:{
                  items:4
              }
          }
        });
      }
  }

	render() {
      const {popularpackage, destname} = this.state;
      if(!popularpackage.length)
        return null;

      return (
    		<section className="selling-block best-places popular-packages-v1 scroll-block" id="packages-view">
        		<div className="container">
           			<div className="row">
              			<div className="col-sm-12">
                 			<div className="selling-inner-block">
                    			<div className="sm-heading-main">
                      				<h4>Popular Packages {destname}</h4>
                      				<p>Unlimited Choices. Fully Customizable. Best Prices.</p>
                    			</div>
                    			<div className="gallery-block">
                       				<div className="owl-carousel owl-theme package-slides" ref="packagesSlider">
                                  {
                                    popularpackage.map((obj, index) => (
                                      <DestinationGuidePopularPackageSlide key={obj.slug} slideData = {obj} />
                                    ))
                                  }
                       				</div>
                    			</div>
			                    
			                </div>
			            </div>
			        </div>
			    </div>
			</section>
    	);
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationGuidePopularPack: (params) => {
      return dispatch(destinationGuidePopularPack(params));
    }
  };
}

export default connect(null, mapDispatchToProps)(DestinationGuidePopularPackages);