import React, { Component } from 'react';
import TestimonialSlides from './TestimonialSlides';
import { connect } from 'react-redux';
import { testimonialList, testimonialListSuccess } from '../../actions/testimonial';


class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      chkTestimonial: props.chkTestimonial,
      status: props.status
    };
  }

  componentDidMount() {
    this.props.testimonialList().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.testimonialListSuccess(response.value.data.payload);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'testimonials') {
      this.setState({
        chkTestimonial: nextProps.chkTestimonial,
        status: nextProps.status
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status == this.state.status)
      return;
    window.addEventListener('load', this.bindSlider);
    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  bindSlider() {
    const $ = window.$$;
    $(this.refs.testimonialSlides).owlCarousel({
      loop: true,
      margin: 15,
      pagination: false,
      nav: true,
      autoHeight: true,
      navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
      responsive: {
        0: {
          items: 1
        },
        500: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    })
  }



  render() {
    return (
      <section className="testimonal-block">
        <div className="pulse-circle">
          <svg className="pulse-svg" width="153px" height="153px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <circle className="circle first-circle" fill="#F87038" cx="25" cy="25" r="25"></circle>
            <circle className="circle second-circle" fill="#F87945" cx="25" cy="25" r="25"></circle>
            <circle className="circle third-circle" fill="#F88353" cx="25" cy="25" r="25"></circle>
            <circle className="circle circle-main" cx="25" cy="25" r="25"></circle>
          </svg>
        </div>
        <div className="pulse-circle pulse2">
          <svg className="pulse-svg" width="153px" height="153px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <circle className="circle first-circle" fill="#F87038" cx="25" cy="25" r="25"></circle>
            <circle className="circle second-circle" fill="#F87945" cx="25" cy="25" r="25"></circle>
            <circle className="circle third-circle" fill="#F88353" cx="25" cy="25" r="25"></circle>
            <circle className="circle circle-main" cx="25" cy="25" r="25"></circle>
          </svg>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-sm-12">
              <div className="testimonal-heading">
                <figure className="quotes"> <img src="images/ic_quote.svg" alt="" /> </figure>
                <label>#RealReviews</label>
                <h3>Testimonials</h3>
              </div>
              <div className="testimonal-carousel">
                <div className="owl-carousel owl-theme testimona-slides" ref="testimonialSlides">

                  {
                    this.state.chkTestimonial && this.state.chkTestimonial.length > 0 &&
                    this.state.chkTestimonial.map((obj, index) => (
                      <TestimonialSlides key={index} slideData={obj} />
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkTestimonial: [...state.testimonial.testimoniallist],
    status: state.testimonial.status,
    compName: state.testimonial.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    testimonialList: () => {
      return dispatch(testimonialList());
    },
    testimonialListSuccess: (payload) => {
      dispatch(testimonialListSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(Testimonials);