// Vikas
import React, { Component } from 'react';
import PackageItineraryItem from './PackageItineraryItem';
import PackageItinerarySkeleton from './PackageItinerarySkeleton';

class PackageItinerary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			package_itinerary: typeof props.package_itinerary !== 'undefined' ? props.package_itinerary : []
		};
	}

	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.package_itinerary !== 'undefined' && this.state.package_itinerary.length != nextProps.package_itinerary.length) {
			this.setState({
				package_itinerary: nextProps.package_itinerary
			});
		}
	}

	render() {
		return (
			<section className="ltierary-block scroll-block" id="ltinerary">
				<div className="container">
					<h2 className="heading-section">Itinerary</h2>
					{/* <div className="ltierray-right-block">
						<a href="javascript:void(0);" className="n-blue-btn">FULL ITINERARY</a>
						<a href="javascript:void(0);" className="download-icon">
							<img src="/images/download-icon.svg" alt="" />
						</a>
					</div> */}
					<div className="block-ltinary-in">
						<div className="row">
							<div className="col-12">
								{
									this.state.package_itinerary.length > 0
										?
										this.state.package_itinerary.map((obj, index) => (
											<PackageItineraryItem key={index} obj={obj} />
										))
										:
										<>
											<PackageItinerarySkeleton />
											<PackageItinerarySkeleton />
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default PackageItinerary;