import React, { Component } from "react";
import { connect } from "react-redux";
import DateRangePicker from "../../common/DateRangePicker";
import DateMonthPicker from "../../common/DateMonthPicker";
import QuantityBox from "../../common/QuantityBox";
import Dropdown from "../../common/Dropdown";
import Error from "../../common/Error";
import AllDestinations from "../../common/AllDestinations";
import { enquiryForm } from "../../../actions/customPackage";
import GooglePlacesPredictions from "../../common/GooglePlacesPredictions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class QueryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destinations: props.destinations,
      data: {
        ...props.data,
        flexible_timing:
          typeof props.data.flexible_timing !== "undefined"
            ? props.data.flexible_timing
            : false,
      },
      loading: false,
      status: props.status,
      errors: props.errors,
      error: false,
    };

    this.submit = this.submit.bind(this);
  }

  // Toggle between Fixed Date or Month
  toggleFlexibleTiming(e) {
    e.preventDefault();
    this.setState({
      data: {
        ...this.state.data,
        flexible_timing: !this.state.data.flexible_timing,
      },
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const {
      selected_theme,
      destination,
      departure,
      email_id,
      contact_number,
      country_code,
      no_of_days,
      departure_date,
      name,
      travelWith,
    } = this.refs;
    // Call action

    const payload = {
      destination: destination.state.value,
      departure: departure.state.value,
      email_id: email_id.value,
      name: name.value,
      contact_number: contact_number.value,
      country_code: country_code.refs.country_code.value,
      no_of_days: no_of_days.refs.no_of_days.value,
      departure_date: departure_date.state.selectedValue,
      travelWith: travelWith.value,
    };

    // return

    this.props
      .enquiryForm(payload)
      .then((response) => {

        this.setState({
          loading: false,
        });
        // Handle successful response
        toast.success("Your request has been submitted successfully");
        setTimeout(() => {
          this.props.hideForm();
        }, 600);

        // Optionally, you can perform additional actions here
      })
      .catch((err) => {

        this.setState({
          loading: false,
        });

        // Handle error
        if (err) {
          // Handle validation errors
          this.setState({
            errors: err.response.data.error,
          });
        }
        toast.error(err.message);
        // Update component state or perform other actions based on the error
      });
  }
  render() {
    const { samePackage } = this.props;
    const { themes, destinations, errors } = this.state;
    const {
      destination,
      departure,
      email_id,
      contact_number,
      country_code,
      no_of_days,
      departure_date,
      name,
      flexible_timing,
    } = this.state.data;
    const countryCodes = ["+91", "+01"];

    return (
      <div className="customize-right-block ">
        <div className="right-top-block">
          <h3>Your Preferences</h3>
          <button type="button" className="close cncl-btn" data-dismiss="modal">
            CLOSE
          </button>
        </div>
        <div className="customize-form-block">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">
                  WHERE YOU WANT TO GO
                </label>
                {
                  <AllDestinations
                    index={this.props.index}
                    realOnly={this.props.realOnly}
                    selectedValue={destination}
                    ref="destination"
                  />
                }
                {typeof errors.destination !== "undefined" && (
                  <Error text={errors.destination} />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title">LEAVING FROM</label>
                <GooglePlacesPredictions
                  value={departure}
                  ref="departure"
                  placeholder="Eg. Mohali"
                />
                {typeof errors.departure !== "undefined" && (
                  <Error text={errors.departure} />
                )}
              </div>
            </div>
            <div className="col-sm-6 ft-block">
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <label className="form-title">&nbsp;</label>
                    <ul>
                      <li>
                        <button
                          className={`flexible-timing ${
                            flexible_timing ? "active" : ""
                          }`}
                          onClick={this.toggleFlexibleTiming.bind(this)}
                        >
                          <ion-icon name="resize" />
                          Flexible Timings
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-title">Duration</label>
                    <div className="person-block sidenumber">
                      <div className="age-block">
                        <QuantityBox
                          min={2}
                          selectedValue={no_of_days}
                          name="no_of_days"
                          ref="no_of_days"
                        />
                      </div>
                    </div>
                    {typeof errors.no_of_days !== "undefined" && (
                      <Error text={errors.no_of_days} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">
                  {flexible_timing ? "DEPARTURE MONTH" : "DEPARTURE DATE"}
                </label>
                <div className="custom-datepicker">
                  {flexible_timing ? (
                    <DateMonthPicker
                      selectedValue={departure_date}
                      single={true}
                      name="departure_date"
                      ref="departure_date"
                    />
                  ) : (
                    <DateRangePicker
                      selectedValue={departure_date}
                      single={true}
                      name="departure_date"
                      ref="departure_date"
                    />
                  )}
                </div>
                {typeof errors.departure_date !== "undefined" && (
                  <Error text={errors.departure_date} />
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">Travelling With</label>
                <select name="travelWith" ref="travelWith">
                  <option>Solo</option>
                  <option>Couple</option>
                  <option>Family</option>
                  <option>Friends</option>
                </select>
                {typeof errors.travelWith !== "undefined" && (
                  <Error text={errors.name} />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">YOUR EMAIL ID</label>
                <input
                  ref="email_id"
                  type="text"
                  placeholder="you@example.com"
                  defaultValue={email_id}
                />
                {typeof errors.email_id !== "undefined" && (
                  <Error text={errors.email_id} />
                )}
              </div>
            </div>

            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="form-title required">Your Name</label>
                <input ref="name" type="text" placeholder="Name" />
                {typeof errors.name !== "undefined" && (
                  <Error text={errors.name} />
                )}
              </div>
            </div>

            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="form-title required">
                  YOUR CONTACT NUMBER
                </label>
                <div className="number-field">
                  <div className="custom-selecter">
                    <Dropdown
                      selectedValue={country_code}
                      options={countryCodes}
                      name="country_code"
                      ref="country_code"
                    />
                  </div>
                  <div className="pn-field">
                    <input
                      defaultValue={contact_number}
                      ref="contact_number"
                      type="tel"
                      className="form-control"
                      placeholder="99999-99999"
                    />
                  </div>
                </div>
                {typeof errors.country_code !== "undefined" && (
                  <Error text={errors.country_code} />
                )}
                {typeof errors.contact_number !== "undefined" && (
                  <Error text={errors.contact_number} />
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <button
                className={`cta-btn ${this.state.loading ? 'loading' : ''}}`}
                type="submit"
                disabled={this.state.loading}
                onClick={this.submit}
              >
               {this.state.loading ? 'Loading...' : ' PLAN MY HOLIDAYS'}
                <i className="next-iocn">
                  <img src="/images/Proceed_icon.svg" alt="" />
                </i>
              </button>
            </div>
            <div className="col-sm-12 secure-content">
              <p>
                Your Information will
                be secured & confidential
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  const data = typeof ownProps.data !== "undefined" ? ownProps.data : {};
  return {
    data: {
      ...state.CustomPackage.firstStep,
      email_id: state.Auth.user.email,
      name: state.Auth.user.name,
      contact_number: state.Auth.user.contact_number,
      country_code: state.Auth.user.country_code,
      ...data,
    },
    status: state.CustomPackage.status,
    compName: state.CustomPackage.compName,
    errors: { ...state.CustomPackage.errors },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enquiryForm: (data) => {
      return dispatch(enquiryForm(data));
    },
  };
};

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(QueryForm);

// export default connect(mapStatesToProps, { saveFirstStep, fetchFirstStep, enquiryForm })(QueryForm);
