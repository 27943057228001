import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthWidget from './AuthWidget';
import LoginModal from './LoginModal';

class AuthLink extends Component {

  showModal() {
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auth !== true && nextProps.auth === true) {
      this.hideModal();
    }
  }

  render() {
    return (
      <>
        {/* {
          this.props.auth === false
            ?
            <a href="javascript:void(0);" id="login" onClick={this.showModal.bind(this)}>Login</a>
            :
            <AuthWidget />
        } */}
      </>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    auth: state.Auth.auth
  }
}

export default connect(mapStatesToProps)(AuthLink);