import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimilarTypePackageSlide from './SimilarTypePackageSlide';

export default class SimilarTypePackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      similar_package: typeof props.similar_package !== 'undefined' ? props.similar_package : []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.similar_package.length != nextProps.similar_package.length) {

      this.setState({
        similar_package: nextProps.similar_package
      });

      setTimeout(() => {

      });
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.bindSlider();
    }, 500)
  }

  bindSlider() {
    const $ = window.$$;
    if ($(window).width() > 991) {
      $(this.refs.packageSlides).owlCarousel({
        loop: false,
        margin: 15,
        pagination: false,
        nav: true,
        navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
        responsive: {
          0: {
            items: 1.2
          },
          400: {
            items: 1.4
          },
          500: {
            items: 2.2
          },
          768: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      });
    }
  }

  render() {
    if (!this.state.similar_package.length)
      return null;

    return (
      <section className="h-package-block similar-package">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-5">
              <div className="similar-package-primary">
                <h3>Similar Packages</h3>
                <div className="view-all-n">
                  <Link to="/travel-packages" className="n-blue-btn">VIEW ALL<img src="/images/button_arrow.svg" alt="" /></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-7">
              <div className="owl-carousel owl-theme h-slider2" ref="packageSlides">
                {
                  this.state.similar_package.length > 0 && this.state.similar_package.map((obj, index) => {
                    return (
                      <SimilarTypePackageSlide key={index} slideData={obj} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}