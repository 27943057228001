import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SimilarTypePackageSlide extends Component {
  constructor(props){
    super(props);
    this.state = {
      slideData: props.slideData
    }
  }
	render() {
		console.log('slideData', this.state.slideData)
    	return (
    		<div className="item">
           		<div className="carousel-inner">
                  	<a href={"/package/detail/" + this.state.slideData.slug }>
                     	<figure><img src={ this.state.slideData.imagepath } alt=""/></figure>
                     	<figcaption className="package-detail-block">
                      		<div className="trip-days">{ this.state.slideData.duration } Days { this.state.slideData.duration - 1 } Nights</div>
                        	<h5>{ this.state.slideData.city_name ? this.state.slideData.city_name+", ":""  }{ this.state.slideData.country_name }</h5>
                        	<p>{ this.state.slideData.package_name }</p>
                     	</figcaption>
                  	</a>
           		</div>
        	</div>
    	);
    }
}