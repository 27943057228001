import React, { Component, Fragment } from 'react';
import ShowMore from 'react-show-more';
import DestinationGuideBanner from './components/destination/DestinationGuideBanner.js';
import DestinationGuidePopular from './components/destination/DestinationGuidePopular';
//import DestinationGuideThingsToDo from './components/destination/DestinationGuideThingsToDo';
import DestinationGuidePopularPackages from './components/destination/DestinationGuidePopularPackages';
import DestinationGuideThingsToDo from './components/destination/DestinationGuideThingsToDo';
import DestinationGuideListenFromLocals from './components/destination/DestinationGuideListenFromLocals';
import WhatToEat from './components/destination/WhatToEat';
import FaqsPanel from './components/common/FaqsPanel';
import { regular_months } from './constants';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from './components/common/loader';
import { destinationGuideDetail } from './actions/destinationGuide';
import { currencyFormat } from './constants';

class DestinationGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destDetail: null,
      status: props.status,
      slug: this.props.match.params.index
    };
  }

  componentDidMount() {
    this.fetchRecords(this.state.slug);
  }

  fetchRecords(slug) {
    this.setState({ destDetail: null });
    this.props.destinationGuideDetail({ to_destination: slug }).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({ destDetail: response.value.data.payload, slug });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.index != nextProps.match.params.index) {
      this.fetchRecords(nextProps.match.params.index);
    }
  }

  render() {
    const { slug, destDetail } = this.state;
    return (
      <Fragment>
        {
          destDetail === null ? <Loader /> :
            <div className="main-body-content">
              {
                this.state.destDetail
                &&
                <DestinationGuideBanner destname={this.state.destDetail.destination_name} destimage={this.state.destDetail.landscape_image} slug={this.state.slug}
                  tagline={this.state.destDetail.tag_line} destTags={this.state.destDetail.tags} index={destDetail.map_address} />
              }
              <section className="transport-guide scroll-block" id="transport-guide">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 col-sm-12">
                      <div className="left-side-transport">
                        <div className="sm-heading-main">
                          <h4>{this.state.destDetail && this.state.destDetail.destination_name} Travel & Tourism Guide</h4>
                        </div>
                        <div className="p-discription-block">
                          {this.state.destDetail &&
                            <ShowMore
                              lines={10}
                              more='READ MORE'
                              less='READ LESS'
                              anchorClass='readmore-link-blue'
                            >
                              <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.description }} />
                            </ShowMore>
                          }
                        </div>
                      </div>

                      <div className="ad-view-other-package">
                        <a href="#packages-view">
                          <figure>
                            <img src="../images/smile-love.svg" alt="" />
                          </figure>
                          <div className="journy-ad-discription">
                            <label>BOOK YOUR JOURNEY NOW</label>
                            <span>{this.state.destDetail && this.state.destDetail.marketing_tagline}</span>
                          </div>
                          <span className="btn-tour-package btn white-btn">Tour Packages</span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                      <div className="qucik-right-info-block">
                        <div className="sm-heading-main">
                          <h4>Quick Information</h4>
                        </div>
                        <ul className="quick-info">
                          {
                            this.state.destDetail
                            &&
                            this.state.destDetail.bestmonth
                            &&
                            <li>
                              <figure>
                                <img src="../images/calendar-icon.svg" alt="" />
                              </figure>
                              <div className="quick-discription">
                                <label>Best time to visit</label>
                                <span>{this.state.destDetail.bestmonth}</span>
                              </div>
                            </li>
                          }
                          {
                            this.state.destDetail
                            &&
                            this.state.destDetail.ideal_days
                            &&
                            <li>
                              <figure>
                                <img src="../images/clock-icon.svg" alt="" />
                              </figure>
                              <div className="quick-discription">
                                <label>IDEAL Duration</label>
                                <span>{this.state.destDetail.ideal_days} Days</span>
                              </div>
                            </li>
                          }
                          {
                            this.state.destDetail
                            &&
                            this.state.destDetail.visa
                            &&
                            <li>
                              <figure>
                                <img src="../images/not-required.svg" alt="" />
                              </figure>
                              <div className="quick-discription">
                                <label>VISA REQUIRED</label>
                                <span>{this.state.destDetail.visa}</span>
                              </div>
                            </li>
                          }
                          {
                            this.state.destDetail
                            &&
                            this.state.destDetail.minimum_price
                            &&
                            <li>
                              <figure>
                                <img src="../images/inr-icon.svg" alt="" />
                              </figure>
                              <div className="quick-discription">
                                <label>STARTS FROM</label>
                                <span>{currencyFormat(parseInt(this.state.destDetail.minimum_price), 'INR')}</span>
                              </div>
                            </li>
                          }

                        </ul>
                        <div className="see-map-btn">
                          {this.state.destDetail && <a href={"https://www.google.com/maps/search/?api=1&query=" + this.state.destDetail.latitude + "," + this.state.destDetail.longitude} target="_blank">
                            <span>See on Map</span>
                            <small>{this.state.destDetail.destination_name}</small>
                          </a>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {
                this.state.destDetail && this.state.destDetail.bestmonth_arr.length > 0 || this.state.destDetail && this.state.destDetail.best_time_visit_description !== "" ?
                  <section className="best-time-block scroll-block" id="best-visit">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8 col-md-12">
                          <div className="left-side-transport">
                            <div className="sm-heading-main">
                              <h4>Best Time to Visit</h4>
                              <p>Plan your trip during best season</p>
                            </div>
                            <div className="p-discription-block">
                              {this.state.destDetail &&
                                <ShowMore
                                  lines={5}
                                  more='READ MORE'
                                  less='READ LESS'
                                  anchorClass='readmore-link-blue'
                                >
                                  <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.best_time_visit_description }} />
                                </ShowMore>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="visit-right-block">
                            <div className="sm-heading-icon">
                              <figure>
                                <img src="../images/calendar-icon.svg" alt="" />
                              </figure>
                              <label>{this.state.destDetail && this.state.destDetail.bestmonth_to}</label>
                            </div>
                            <div className="list-month-view">
                              {
                                this.state.destDetail &&
                                <ul>
                                  {
                                    regular_months.map((obj, index) => (
                                      <li key={index} className={this.state.destDetail.bestmonth_arr.indexOf(obj.id) != -1 ? 'active' : ''}><span>{obj.name}</span></li>
                                    )
                                    )
                                  }
                                </ul>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> : null
              }

              <section className="climate-block">
                <div className="container">
                  <div className="heading-guide">
                    <h2>Climate In {this.state.destDetail && this.state.destDetail.destination_name}</h2>
                  </div>
                  <div className="row">
                    {
                      this.state.destDetail
                      &&
                      this.state.destDetail.summer_season_temp
                      &&
                      <div className="col-md-4">
                        <div className="climate-ui-block summer-view">
                          <div className="view-climage-head">
                            <span>{this.state.destDetail && this.state.destDetail.summer_season_from_month} - {this.state.destDetail && this.state.destDetail.summer_season_to_month}</span>
                            <label>{this.state.destDetail && this.state.destDetail.summer_season_temp}</label>
                            <p>{this.state.destDetail && this.state.destDetail.summer_season_tag_line}</p>
                          </div>
                          <div className="discrition-climate-block">
                            <h2>Summer Season in<br />{this.state.destDetail && this.state.destDetail.destination_name}</h2>
                            {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.summer_season }} />}
                          </div>
                        </div>
                      </div>
                    }
                    {
                      this.state.destDetail
                      &&
                      this.state.destDetail.monsoon_season_temp
                      &&
                      <div className="col-md-4">
                        <div className="climate-ui-block monsoon-view">
                          <div className="view-climage-head">
                            <span>{this.state.destDetail && this.state.destDetail.monsoon_season_from_month} - {this.state.destDetail && this.state.destDetail.monsoon_season_to_month}</span>
                            <label>{this.state.destDetail && this.state.destDetail.monsoon_season_temp}</label>
                            <p>{this.state.destDetail && this.state.destDetail.monsoon_season_tag_line}</p>
                          </div>
                          <div className="discrition-climate-block">
                            <h2>Monsoon Season in <br />{this.state.destDetail && this.state.destDetail.destination_name}</h2>
                            {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.monsoon_season }} />}
                          </div>
                        </div>
                      </div>
                    }
                    {
                      this.state.destDetail
                      &&
                      this.state.destDetail.winter_season_temp
                      &&
                      <div className="col-md-4">
                        <div className="climate-ui-block winter-view">
                          <div className="view-climage-head">
                            <span>{this.state.destDetail && this.state.destDetail.winter_season_from_month} - {this.state.destDetail && this.state.destDetail.winter_season_to_month}</span>
                            <label>{this.state.destDetail && this.state.destDetail.winter_season_temp}</label>
                            <p>{this.state.destDetail && this.state.destDetail.winter_season_tag_line}</p>
                          </div>
                          <div className="discrition-climate-block">
                            <h2>Winter Season in <br />{this.state.destDetail && this.state.destDetail.destination_name}</h2>
                            {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.winter_season }} />}
                          </div>
                        </div>
                      </div>
                    }

                    {
                      this.state.destDetail
                      &&
                      this.state.destDetail.spring_season_temp
                      &&
                      <div className="col-md-4">
                        <div className="climate-ui-block spring-view">
                          <div className="view-climage-head">
                            <span>{this.state.destDetail && this.state.destDetail.spring_season_from_month} - {this.state.destDetail && this.state.destDetail.spring_season_to_month}</span>
                            <label>{this.state.destDetail && this.state.destDetail.spring_season_temp}</label>
                            <p>{this.state.destDetail && this.state.destDetail.spring_season_tag_line}</p>
                          </div>
                          <div className="discrition-climate-block">
                            <h2>Spring Season in<br />{this.state.destDetail && this.state.destDetail.destination_name}</h2>
                            {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.spring_season }} />}
                          </div>
                        </div>
                      </div>
                    }
                    {
                      this.state.destDetail
                      &&
                      this.state.destDetail.fall_season_temp
                      &&
                      <div className="col-md-4">
                        <div className="climate-ui-block fall-view">
                          <div className="view-climage-head">
                            <span>{this.state.destDetail && this.state.destDetail.fall_season_from_month} - {this.state.destDetail && this.state.destDetail.fall_season_to_month}</span>
                            <label>{this.state.destDetail && this.state.destDetail.fall_season_temp}</label>
                            <p>{this.state.destDetail && this.state.destDetail.fall_season_tag_line}</p>
                          </div>
                          <div className="discrition-climate-block">
                            <h2>Fall Season in <br />{this.state.destDetail && this.state.destDetail.destination_name}</h2>
                            {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.fall_season }} />}
                          </div>
                        </div>
                      </div>
                    }
                    {
                      this.state.destDetail
                      &&
                      this.state.destDetail.autumn_season_temp
                      &&
                      <div className="col-md-4">
                        <div className="climate-ui-block autumn-view">
                          <div className="view-climage-head">
                            <span>{this.state.destDetail && this.state.destDetail.autumn_season_from_month} - {this.state.destDetail && this.state.destDetail.autumn_season_to_month}</span>
                            <label>{this.state.destDetail && this.state.destDetail.autumn_season_temp}</label>
                            <p>{this.state.destDetail && this.state.destDetail.autumn_season_tag_line}</p>
                          </div>
                          <div className="discrition-climate-block">
                            <h2>Autumn Season in <br />{this.state.destDetail && this.state.destDetail.destination_name}</h2>
                            {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.autumn_season }} />}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </section>

              {
                this.state.destDetail && this.state.destDetail.reach_by_air !== "" || this.state.destDetail && this.state.destDetail.reach_by_road !== "" ||
                  this.state.destDetail && this.state.destDetail.reach_by_sea !== "" || this.state.destDetail && this.state.destDetail.reach_by_train !== "" ?
                  <section className="vehicle-features-block scroll-block" id="how-teach">
                    <div className="container">
                      <div className="sm-heading-main">
                        <h4>How to reach {this.state.destDetail && this.state.destDetail.destination_name}</h4>
                        <p>Read about the best & quickest {this.state.destDetail && this.state.destDetail.destination_name} transport routes, travel guide to save time & money.</p>
                      </div>
                      <div className="row">
                        {
                          this.state.destDetail
                          &&
                          this.state.destDetail.reach_by_air
                          &&
                          <div className="col-md-4">
                            <div className="vechile-ui-block">
                              <div className="head-vechile-ui">
                                <figure>
                                  <img src="../images/air-freight.svg" alt="" />
                                </figure>
                                <label>By Air</label>
                              </div>
                              <div className="discription-vechile">
                                {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.reach_by_air }} />}
                              </div>
                            </div>
                          </div>
                        }
                        {
                          this.state.destDetail
                          &&
                          this.state.destDetail.reach_by_train
                          &&
                          <div className="col-md-4">
                            <div className="vechile-ui-block">
                              <div className="head-vechile-ui">
                                <figure>
                                  <img src="../images/train-icon.svg" alt="" />
                                </figure>
                                <label>By Train</label>
                              </div>
                              <div className="discription-vechile">
                                {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.reach_by_train }} />}
                              </div>
                            </div>
                          </div>
                        }
                        {
                          this.state.destDetail
                          &&
                          this.state.destDetail.reach_by_road
                          &&
                          <div className="col-md-4">
                            <div className="vechile-ui-block">
                              <div className="head-vechile-ui">
                                <figure>
                                  <img src="../images/car-icon.svg" alt="" />
                                </figure>
                                <label>By Road</label>
                              </div>
                              <div className="discription-vechile">
                                {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.reach_by_road }} />}
                              </div>
                            </div>
                          </div>
                        }

                        {
                          this.state.destDetail
                          &&
                          this.state.destDetail.reach_by_sea
                          &&
                          <div className="col-md-4">
                            <div className="vechile-ui-block">
                              <div className="head-vechile-ui">
                                <figure>
                                  <img src="../images/sea-icon.svg" alt="" />
                                </figure>
                                <label>By Sea</label>
                              </div>
                              <div className="discription-vechile">
                                {this.state.destDetail && <p dangerouslySetInnerHTML={{ __html: this.state.destDetail.reach_by_sea }} />}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </section> : null
              }
              {
                this.state.destDetail !== null
                &&
                <DestinationGuidePopular destination={this.state.destDetail.destination_name} slug={this.state.slug} />
              }
              {
                this.state.destDetail
                &&
                this.state.destDetail.whattoeat.length > 0
                &&
                <WhatToEat whattoeat={this.state.destDetail.whattoeat} destname={this.state.destDetail.destination_name} />
              }
              {
                this.state.destDetail !== null
                &&
                <DestinationGuideThingsToDo destination={this.state.destDetail.destination_name} slug={this.state.slug} id={this.state.destDetail.id} />
              }
              {
                <DestinationGuidePopularPackages slug={this.state.slug} />
              }
              {/* <DestinationGuideListenFromLocals /> */}
              {this.state.destDetail && this.state.destDetail.faq.length > 0 && <FaqsPanel faq={this.state.destDetail.faq} />}
            </div>
        }
      </Fragment>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    destDetail: state.destinationGuide.destinationguidedetail ? state.destinationGuide.destinationguidedetail : null,
    status: state.destinationGuide.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationGuideDetail: (params) => {
      return dispatch(destinationGuideDetail(params));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DestinationGuide);