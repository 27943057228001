import React, {Component} from "react";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";

const API_KEY = "AIzaSyAbUvt0ZH3bA1NwGC6u6udX2JQruEnRwtE";

export default class GooglePlacesPredictions extends Component {

  constructor(props){
    super(props);
    this.state = {
      search: "",
      disable: typeof props.disable !== 'undefined' ? props.disable : false,
      placeholder: typeof props.placeholder !== 'undefined' ? props.placeholder : 'Eg. Singapore',
      value: typeof props.value !== 'undefined' ? props.value : '',
      lat: typeof props.lat !== 'undefined' ? props.lat: '',
      lng: typeof props.lng !== 'undefined' ? props.lng: ''
    };
  }

  handleInputChange(e) {
    this.setState({search: e.target.value, value: e.target.value})
  }

  handleSelectSuggest(suggest) {
    this.setState({search: "", value: suggest.formatted_address, lat: suggest.geometry.location.lat(), lng: suggest.geometry.location.lng()});
  }

	render() {
      const {search, value, disable, placeholder} = this.state;

      if(disable === true){
        return <input disabled={true} value={value} type="text" placeholder={placeholder} onChange={this.handleInputChange.bind(this)} />
      }

    	return (
    		<ReactGoogleMapLoader
          params={{
            key: API_KEY,
            libraries: "places",
          }}
          render={googleMaps =>
            googleMaps && (
              <div>
                <ReactGooglePlacesSuggest
                  autocompletionRequest={{input: search}}
                  googleMaps={googleMaps}
                  onSelectSuggest={this.handleSelectSuggest.bind(this)}
                >
                  <input
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    className="google-predictions"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </ReactGooglePlacesSuggest>
              </div>
            )
          }
        />
    	);
    }
}