import React, { Component } from 'react';
import TravelPackageListing from './TravelPackageListing';
import BreadCrumb from '../common/BreadCrumb';
import TotalResultCounter from '../common/TotalResultCounter'
import { connect } from 'react-redux';

class TravelPackageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travelPackage: props.travelPackage,
      status: props.status
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'packages') {
      this.setState({
        travelPackage: nextProps.travelPackage,
        status: nextProps.status
      })
    }
  }
  render() {
    console.log('ggggggg', this.state.travelPackage.data)
    return (
      <div className="listing-right-block">
        <div className="right-heading-block">
          <BreadCrumb breadcrumb={this.state.travelPackage.breadcrumb} />
          <TotalResultCounter totalresult={this.state.travelPackage.total} />
        </div>
        <TravelPackageListing data={this.state.travelPackage.data} />
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    travelPackage: { ...state.packages.travelpackages },
    status: state.packages.status,
    compName: state.packages.compName
  }
}

export default connect(mapStatesToProps)(TravelPackageSection);