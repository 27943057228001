import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { searchPackages, searchPackagesSuccess } from '../../actions/packages';
import ExploreSearch from './ExploreSearch';

class HomeSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searching: props.searching,
			status: props.status,
			explore: false,
			compName: '',
			searched: false,
			searchResults: null
		};
		this.searchChange = this.searchChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	searchChange(e) {
		this.setState({ searched: true })
		const { value } = e.target;
		// this.setState({
		// 	explore: true
		// });
		if (value.length > 2) {
			this.props.searchPackages({ query: e.target.value }).then((response) => {
				if (response.value.data.result === 'success') {
					console.log(response.value.data.payload, 'fffffff666')
					//this.props.searchPackagesSuccess(response.value.data.payload);
					this.setState({searchResults: response.value.data.payload})
				}
			});
		}else{
			this.setState({ searched: false })
		}
	}

	onFocus() {
		this.setState({
			explore: true
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.status != nextProps.status && nextProps.compName == 'search') {
			this.setState({
				searching: nextProps.searching,
				status: nextProps.status,
				compName: nextProps.compName
			});
		}
	}

	render() {
		return (
			<div className="container mobile-view">
				<div className="banner-top-content">
					<h3>Customize and Book Amazing Holiday Packages</h3>
					<form>
						<div className="banner-form-outr">
							<div className="form-group active">
								<i className=" search-icon">
									<img src="/images/search-icon.svg" alt="" />
								</i>
								<input type="text" placeholder="Try “Switzerland Holiday Packages”" defaultValue='' onChange={this.searchChange} onFocus={this.onFocus} />
								{
									this.state.explore && this.state.explore === true &&
									<ExploreSearch searching={this.state.searching} status={this.state.status} compName={this.state.compName} searched={this.state.searched} searchResults={this.state.searchResults} />
								}
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStatesToProps = (state, ownProps) => {
	return {
		searching: state.packages.searchpackages,
		status: state.packages.status,
		compName: state.packages.compName
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		searchPackages: (params) => {
			return dispatch(searchPackages(params));
		},
		searchPackagesSuccess: (payload) => {
			dispatch(searchPackagesSuccess(payload));
		},

	};
}

export default connect(mapStatesToProps, mapDispatchToProps)(HomeSearch);