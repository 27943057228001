import React, { Component } from 'react';

class InclusionsList extends Component {

  manageIcons = (category, obj, type) => {
    if (category == "Meal Plan") {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].title == "All Inclusive") {
          return (<li><figure><img src="/images/wine_with_food.png" alt="" /></figure><figcaption> <p>All inclusive</p></figcaption></li>)
        }
      }
      return (<li><figure><img src="/images/food-icon.svg" alt="" /></figure><figcaption> <p>Meals</p></figcaption></li>)

    } else if (category == "Stay/Room") {
      return (<li><figure><img src="/images/hotel-icon.svg" alt="" /></figure><figcaption> <p>Stay</p></figcaption></li>)
    } else if (category == "Tour Inclusions") {
      return (<li><figure><img src="/images/sight-seen.svg" alt="" /></figure><figcaption> <p>Tours</p></figcaption></li>)
    } else if (category == "Activities") {
      return (<li><figure><img src="/images/snorkling.png" alt="" /></figure><figcaption> <p>Activities</p></figcaption></li>)
    } else if (category == "Other Inclusions") {
      if(type && type == 'listing'){
        return (<li><figure><img src="/images/chat_lock.png" alt="" /></figure><figcaption> <p>Other inclusions</p></figcaption></li>)
      }
      return obj.map((o, i) => {
        return (
          <>
            {
              obj[i].title == "Visa Fee" ? <li><figure><img src="/images/visa@2x.svg" alt="" /></figure><figcaption> <p>Visa</p></figcaption></li> : null
            }
            {
              obj[i].title == "Insurance" ? <li><figure><img src="/images/insurance@2x.svg" alt="" /></figure><figcaption> <p>Insurance</p></figcaption></li> : null
            }
          </>
        )
      })
    } else if (category == "Transfers") {
      if(type && type == "listing"){
        return;
      }
      return obj.map((o, i) => {
        return (
          <>
            {
              obj[i].title == "Transfers" ? <li><figure><img src="/images/car-icon.svg" alt="" /></figure><figcaption> <p>Transfers</p></figcaption></li> : null
            }

            {
              obj[i].title == "Internal Flight" ? <li><figure><img src="/images/flight-icon.svg" alt="" /></figure><figcaption> <p>Flight</p></figcaption></li> : null
            }
            {
              obj[i].title == "Internal Train" ? <li><figure><img src="/images/train-icon.svg" alt="" /></figure><figcaption> <p>Train</p></figcaption></li> : null
            }
            {
              obj[i].title == "Speed Boat" ? <li><figure><img src="/images/speed_boat@2x.svg" alt="" /></figure><figcaption> <p>Boat</p></figcaption></li> : null
            }
            {
              obj[i].title == "Sea Plane" ? <li><figure><img style={{width: "55px"}} src="/images/sea_plane@2x.svg" alt="" /></figure><figcaption> <p>Sea Plane</p></figcaption></li> : null
            }
            {
              obj[i].title == "Ferries" ? <li><figure><img src="/images/cruise-icon.svg" alt="" /></figure><figcaption> <p>Ferries</p></figcaption></li> : null
            }
          </>
        )

      })
    }
  }
  render() {
    const { grouped, type } = this.props;
    
    return (
      <ul>
        {this.manageIcons("Stay/Room", grouped["Stay/Room"], type)}
        {this.manageIcons("Meal Plan", grouped["Meal Plan"], type)}
        {this.manageIcons("Tour Inclusions", grouped["Tour Inclusions"], type)}
        {this.manageIcons("Transfers", grouped["Transfers"], type)}
        {this.manageIcons("Activities", grouped["Activities"], type)}
        {this.manageIcons("Other Inclusions", grouped["Other Inclusions"], type)}


      </ul>
    );
  }
}
export default InclusionsList;