import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';

export default class TestimonialSlides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    }
  }


  render() {
    return (
      <div className="item">
        <div className="testimonal-slider-content">
          <figcaption>
            <label className="rating-block">{this.state.slideData.rating}</label>
            <p dangerouslySetInnerHTML={{ __html: this.state.slideData.detail }}></p>
          </figcaption>
          <figure>
            <div className="profile-img"><img src={this.state.slideData.image} alt="" />
            </div>
            <div className="profile-deatil">
              <h5>{this.state.slideData.author}</h5>
              <p>{this.state.slideData.location}</p>
            </div>
          </figure>
        </div>
      </div>
    );
  }
}