import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import AuthLink from '../../AuthLink';
import AuthModals from '../../AuthModals';
import { connect } from 'react-redux';
import { headerMainMenu, headerMainMenuSuccess } from '../../actions/navigation';

class ThemeMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chkNavigation: props.chkNavigation,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.headerMainMenu().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.headerMainMenuSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      if (this.props.status != nextProps.status) {
        this.setState({
          chkNavigation: nextProps.chkNavigation,
          status: nextProps.status
        })
      }
    }
  }

  render() {
    return (
      <div className="custom-dropdown-nav img-hide">
        <div className="submenu-block theme-listing">
          <h4>Themes Packages</h4>
          <ul>
            {
              this.state.chkNavigation.theme_menu && this.state.chkNavigation.theme_menu.length > 0 &&
              this.state.chkNavigation.theme_menu.map((obj, index) => (
                <li key={index}>
                  <Link to={"/travel-packages/" + obj.slug +"?categories[]="+obj.slug}>
                    <span className={"theme-icons t-icon" + parseInt(index + 1)}></span>
                    {obj.title}
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    headerMainMenu: () => {
      return dispatch(headerMainMenu());
    },
    headerMainMenuSuccess: (payload) => {
      dispatch(headerMainMenuSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(ThemeMenu);