import React, { Component } from 'react';

export default class ChatWithUsBlueBox extends Component {
	render() {
    	return (
    		<div className="queryblock">
                <figure>
                	<img src="/images/think-emo.svg" alt="" />
                </figure>
                <div className="query-content">
                	<h4>Do you have more queries?</h4>
                    <p>If your question is not listed you can directly ask a question by clicking on Ask now. Our team will assist you <strong>ASAP</strong>.</p>
                    <a href="https://wa.me/919014748234"  target="_blank" className="chat-btn"><img src="/images/chat-icon-btn.svg" alt="" /> Chat with us</a>
                </div>
            </div>
    	);
    }
}