import React, { Component } from 'react';

export default class Newsletter extends Component {
   render() {
      return (
         <div className="footer-subscribe">
            {/* <div className="form-group">
               <input type="text" placeholder="SUBSCRIBE FOR NEWSLETTER" />
               <button className="send-btn"><img src="/images/ic_subscribe_arrow.svg" alt="" /></button>
            </div> */}
         </div>
      );
   }
}