import React, { Component } from "react";
import CustomizeRequestModal from "./CustomizeRequestModal";
import EnquiryRequestModal from "./EnquiryRequestModal";
import ContactDetail from "./components/partials/request/ContactDetail";

export default class PlanHolidayButton extends Component {
  constructor(props) {
    super(props);
    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
    this.showCustomRequestContactForm = this.showCustomRequestContactForm.bind(
      this
    );
  }
  componentDidMount() {
    const submitContactFrom = localStorage.getItem("contactFromSubmit");
    if (window.location.pathname === "/" && submitContactFrom == null) {
      setTimeout(() => {
        this.showCustomRequestContactForm();
      }, 10000);
    }
  }
  hideCustomRequestForm() {
    window.getFooter().setState({
      renderElement: null,
    });
  }

  showCustomRequestForm() {
    window.getFooter().setState({
      renderElement: (
        <CustomizeRequestModal toggle={this.hideCustomRequestForm} />
      ),
    });
  }
  showCustomRequestContactForm() {
    window.getFooter().setState({
      renderElement: <ContactDetail toggle={this.hideCustomRequestForm} />,
    });
  }

  render() {
    return (
      <>
        <div style={{ display: "inline-block" }}>
          <a
            href="javascript: void(0);"
            className="btn-1"
            onClick={this.showCustomRequestForm}
          >
            Plan My Holidays
            <img src="/images/slider_icon_next.svg" />
          </a>
        </div>
        {/* <div style={{ display: "inline-block" }}>
          <a
            href="javascript: void(0);"
            className="btn-1"
            onClick={this.showCustomRequestContactForm}
          >
            contact From
            <img src="/images/slider_icon_next.svg" />
          </a>
        </div> */}
      </>
    );
  }
}
