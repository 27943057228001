import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class FooterNavigation extends Component {
  render() {
    return (
      <ul>
        {/* <li><Link to="/about-us">About Us</Link></li> */}
        <li><Link to="/travelstories">Travel Stories</Link></li>
        <li><Link to="/our-partners">Our Partners</Link></li>
        <li><Link to="/help-support">Help & Support</Link></li>
        <li><Link to="/career">Career</Link></li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/contact-us">Contact us</Link></li>
      </ul>
    );
  }
}