import React, { Component } from 'react';
import BlogList from './BlogList';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { homeBlogList, homeBlogListSuccess } from '../../actions/blog';

class TravelBlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeBlog: props.homeBlog,
      totalBlog: props.totalBlog,
      status: props.status
    }
  }

  componentDidMount() {
    // this.props.homeBlogList().then((response) => {
    //   if (response.value.data.result === 'success') {
    //     this.props.homeBlogListSuccess(response.value.data.payload, response.value.data.total);
    //   }
    // })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({
        homeBlog: nextProps.homeBlog,
        totalBlog: nextProps.totalBlog,
        status: nextProps.status
      })
    }
  }
  render() {
    return (
      <section className="blog-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading-block">
                <label>#TRAVEL INSPIRATION</label>
                <h3 className="title">Trending travel blogs</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {
              this.state.homeBlog && this.state.homeBlog.length > 0 &&
              this.state.homeBlog.map((obj, index) => (
                <BlogList key={index} slideData={obj} />
              ))
            }
            <div className="view-all pos-top">
              <Link to="/blog" className="n-blue-btn"> Show ALL ({this.state.totalBlog > 50 ? "50+" : this.state.totalBlog}) <img src="/images/button_arrow.svg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    homeBlog: [...state.blog.homebloglist],
    totalBlog: state.blog.totalblogs,
    status: state.blog.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    homeBlogList: () => {
      return dispatch(homeBlogList());
    },
    homeBlogListSuccess: (payload, total) => {
      dispatch(homeBlogListSuccess(payload, total));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(TravelBlogs);