import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { searchPackages, searchPackagesSuccess } from '../../actions/packages';
import ExploreSearch from '../partials/ExploreSearch';

class InnerHeaderSearch extends Component {
	constructor(props){
	    super(props);
	    this.state = {
	      	searching: props.searching,
	    	status: props.status,
	    	explore: false,
	    	compName: '',
			searchResults: null,
			searched: false
	    };
	    this.searchChange = this.searchChange.bind(this);
	    this.onFocus = this.onFocus.bind(this);
	}

	searchChange(e){
    	const { value } = e.target;
    	this.setState({
    		searched: true
    	});
    	if(value.length > 2){
    		this.props.searchPackages({query : e.target.value}).then((response) => {
		      	if(response.value.data.result === 'success'){
		        	//this.props.searchPackagesSuccess(response.value.data.payload);
					this.setState({searchResults: response.value.data.payload})
		      	}
		    });
    	}else{
			this.setState({ searched: false })
		}
	}

	onFocus(){
		this.setState({
    		explore: true
    	});
	}

	componentWillReceiveProps(nextProps){
	    if(this.props.status != nextProps.status && nextProps.compName == 'search'){
	      	this.setState({
	        	searching: nextProps.searching,
	        	status: nextProps.status,
	        	compName: nextProps.compName
	      	});
	    }
	}

	render() {
		console.log('gggggggg', this.state.searched);
    	return (
	        <div className="search-block active">
	          	<span className="back-search-mob"></span>
	          	<button className="search-icon"><i className=" search-icon"><img src="/images/search-icon.svg" alt="" /></i></button>
	          
	          	<input autocomplete="off" type="text" name="searching" placeholder="Honeymoon Packagess" className="text-field" defaultValue='' onChange={this.searchChange} onFocus={ this.onFocus }  />
             	{
                 	this.state.searched && this.state.searched == true &&
                 	<ExploreSearch searching={ this.state.searching } searched={this.state.searched} status= {this.state.status} compName = {this.state.compName} searchResults={this.state.searchResults} />
                }
	       	</div>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  	return {
    	searching: state.packages.searchpackages,
    	status: state.packages.status,
    	compName: state.packages.compName
  	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchPackages: (params) => {
      return dispatch(searchPackages(params));
    },
    searchPackagesSuccess: (payload) => {
      dispatch(searchPackagesSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(InnerHeaderSearch);