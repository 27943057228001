// Vikas - 09-01-2018
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomizeRequestModal from '../../CustomizeRequestModal';
import DateRangePicker from './DateRangePicker';
import DateMonthPicker from './DateMonthPicker';
import Error from './Error';
import AllDestinations from './AllDestinations';
import { saveFirstStepPlanMyTrip } from '../../actions/customPackage';
import GooglePlacesPredictions from './GooglePlacesPredictions';

class PlanMyHolidayFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      flexible_timing: false,
      errors: {},
      data: {},
      show: false
    };

    this.submit = this.submit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.bind = this.bind.bind(this);
  }

  componentDidMount() {
    this.bind();
  }

  componentWillReceiveProps(nextProps) {
    this.bind();
    if (nextProps.status != this.state.status && nextProps.compName == 'firststep-plan-trip') {
      // if (Object.keys(nextProps.errors).length > 0) {
      //   this.setState({
      //     errors: nextProps.errors,
      //     status: nextProps.status
      //   });
      // } else {
        const { destination, departure, email_id, contact_number, departure_date } = this.refs;
        this.setState({
          show: true,
          data: { destination: destination.state.value, departure: departure.state.value, email_id: email_id.value, contact_number: contact_number.value, departure_date: departure_date.state.selectedValue, flexible_timing: this.state.flexible_timing },
          status: nextProps.status
        });
      // }
    }
  }

  toggle() {
    this.setState({
      show: false
    });
  }

  // Toggle between Fixed Date or Month
  toggleFlexibleTiming(e) {
    e.preventDefault();
    this.setState({
      flexible_timing: !this.state.flexible_timing
    });
  }

  submit(e) {
    e.preventDefault();
    const { destination, departure, email_id, contact_number, departure_date } = this.refs;
    // Call action
    this.props.saveFirstStepPlanMyTrip({ home_request: 1, destination: destination.state.value, departure: departure.state.value, email_id: email_id.value, contact_number: contact_number.value, departure_date: departure_date.state.selectedValue, flexible_timing: this.state.flexible_timing, no_of_days: 2 });
  }

  bind() {
    if (window.$$('.onclick').length > 0) {
      window.$$('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').each(function () {

        if (window.$$(this).val().replace(/\s+/, '') != '')
          window.$$(this).addClass('active');
      });
      window.$$('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').blur(function () {
        if (window.$$(this).val().replace(/\s+/, '') == '') {
          window.$$(this).val('').removeClass('active');
        } else
          window.$$(this).addClass('active');
      });
    }
  }

  render() {
    const { errors, show, flexible_timing, data } = this.state;

    return (
      <div className="col-lg-7 col-md-12 col-sm-12 order-lg-12 ">
        {show && <CustomizeRequestModal data={data} toggle={this.toggle} />}
        <div className="form-right-block">
          <label className="form-title">#PlanMyHoliday</label>
          <div className="heading-block">
            <h4>Our experts would love to create a package just for you! </h4>
            <p> Fill in your requirements here</p>
          </div>
          <div className="form-block">
            <div className="form-group onclick">
              {
                <AllDestinations ref="destination" placeholder="Destination" />
              }
              {typeof errors.destination !== 'undefined' && <Error text={errors.destination} />}
            </div>
            <div className="form-group onclick">
              <GooglePlacesPredictions ref="departure" placeholder="Departing From" />
              {typeof errors.departure !== 'undefined' && <Error text={errors.departure} />}
            </div>
            <div className="form-group datepicker-block">
              {
                flexible_timing
                  ?
                  <DateMonthPicker single={true} name="departure_date" ref="departure_date" />
                  :
                  <DateRangePicker single={true} name="departure_date" ref="departure_date" />
              }
              {typeof errors.departure_date !== 'undefined' && <Error text={errors.departure_date} />}
            </div>
            <div className="form-group">
              <ul>
                <li>OR</li>
                <li>
                  <button className={`flexible-timing ${flexible_timing ? "active" : ""}`} onClick={this.toggleFlexibleTiming.bind(this)}>
                    <ion-icon name="resize"></ion-icon>
                                Flexible Timings
                              </button>
                </li>
              </ul>
            </div>
            <div className="form-group onclick">

              <input ref="email_id" type="text" placeholder="" />
              <label className="title">Email ID*</label>
              {typeof errors.email_id !== 'undefined' && <Error text={errors.email_id} />}
            </div>
            <div className="form-group onclick">

              <input ref="contact_number" type="text" placeholder="" />
              <label className="title">Mobile Number*</label>
              {typeof errors.contact_number !== 'undefined' && <Error text={errors.contact_number} />}
            </div>
            <div className="form-group">
              <input type="submit" value="PLAN MY HOLIDAYS" className="orange-btn" onClick={this.submit} />
            </div>
          </div>
          <div className="secure-text">
            
            <p> Your Information will be secured & confidential</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    status: state.CustomPackage.status,
    compName: state.CustomPackage.compName,
    errors: { ...state.CustomPackage.errors }
  }
}

export default connect(mapStatesToProps, { saveFirstStepPlanMyTrip })(PlanMyHolidayFilter);