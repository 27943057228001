import React, { Component } from 'react';
import { connect } from 'react-redux';
import Error from '../../common/Error';
import TripActivities from '../../common/TripActivities';
import { saveThirdStep, fetchThirdStep } from '../../../actions/customPackage';

class StepThree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            status: props.status,
            submitting: false,
            errors: {}
        };

        this.submit = this.submit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status != this.state.status && nextProps.compName == 'thirdstep') {
            if (Object.keys(nextProps.errors).length > 0) {
                this.setState({
                    errors: nextProps.errors,
                    status: nextProps.status
                });
                return;
            }
            this.setState({
                data: nextProps.data,
                status: nextProps.status
            });
            this.setState({submitting: false})
            this.props.updateStep(4);
        }
    }

    submit(e) {
        e.preventDefault();
        this.setState({submitting: true});
        const { activities, additional_requirements, budget, budget_type } = this.refs;

        // Call action
        this.props.saveThirdStep({ 
            //activities: activities.state.selectedValue.join(','), 
            activities: "",
            additional_requirements: additional_requirements.value, 
            budget: budget.value, 
            quote_id: this.props.quote_id, 
            budget_type: budget_type.value });
       
    }

    goBack(e) {
        e.preventDefault();
        this.props.updateStep(2);
    }

    render() {
        console.log(this.props.submitting, 'ttttt');
        const { errors } = this.state;
        return (
            <div className="customize-right-block third-step" style={{ display: "inline-block" }}>
                <div className="right-top-block">
                    <h3>Almost done! Final Step</h3>
                    <button type="button" className="close cncl-btn" data-dismiss="modal">CLOSE</button>
                </div>
                <div className="customize-form-block">
                    <div className="row">
                        {/* <div className="col-sm-12">
                            <div className="form-group ca-block">
                                <label className="form-title">CHOOSE ACTIVITIES </label>
                                <TripActivities ref="activities" activities={this.props.activities} />
                            </div>
                        </div> */}
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className="form-title">MENTION YOUR BUDGET</label>
                                <input ref="budget" type="text" name="budget" placeholder="Eg. 40,000 - 70,000" />
                                {typeof errors.budget !== 'undefined' && <Error text={errors.budget} />}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className="form-title">&nbsp;</label>
                                <select name="budget_type" ref="budget_type">
                                    <option>per person</option>
                                    <option>total</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-11 col-sm-12">
                            <div className="form-group">
                                <label className="form-title">ADDITIONAL REQUIREMENTS</label>
                                <textarea ref="additional_requirements" placeholder="Do you want to add something which is not listed above or any special requests? Mention it here…"></textarea>
                            </div>
                        </div>
                        <div className="col-sm-12 cp-btn-block">
                            <button className="btn outline-btn back2" onClick={this.goBack}> Back</button>
                            <button className="cta-btn step3" type="submit" disabled={this.state.submitting ? true: false} onClick={this.submit}>{this.state.submitting ? "Submitting...": "Submit"} <i className="next-iocn"><img src="/images/Proceed_icon.svg" alt="" /></i></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        data: { ...state.CustomPackage.thirdStep },
        quote_id: state.CustomPackage.firstStep.quote_id,
        status: state.CustomPackage.status,
        compName: state.CustomPackage.compName,
        errors: { ...state.CustomPackage.errors }
    }
}

export default connect(mapStatesToProps, { saveThirdStep, fetchThirdStep })(StepThree);