import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import { currencyFormat } from '../../constants';

export default class BestCurrentMonthPlacesSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    }
  }
  render() {
    return (
      <div className="item">
        <div className="carousel-inner">
          <Link target = "_blank" to={"/destinationpackage/" + this.state.slideData.slug}>
            <figure><img src={this.state.slideData.landscape_image} alt="" /></figure>
            <figcaption className="package-detail-block">
              <ul className="or-btn">
                <li className="tag-btn">
                  {/* <ion-icon ios="ios-flame" md="ios-flame"></ion-icon> */}
                  <span>Featured</span>
                </li>
                <li>{this.state.slideData.tags}</li>
              </ul>
              <h5>{this.state.slideData.destination_name}</h5>
              <p>{this.state.slideData.tag_line}</p>
              <label className="price-label">Starts from {currencyFormat(this.state.slideData.minimumprice, 'INR')}/-</label>
            </figcaption>
          </Link>
        </div>
      </div>
    );
  }
}