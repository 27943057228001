import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginModal from '../../LoginModal';
import SignupModal from '../../SignupModal';
import CustomizeRequestModal from '../../CustomizeRequestModal';
import { headerMainMenu, headerMainMenuSuccess } from '../../actions/navigation';

class MobileNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null,
      dropdownType: null,
      chkNavigation: props.chkNavigation,
      status: props.status,
      nav: props.nav
    };
    this.toggle = this.toggle.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showSignupModal = this.showSignupModal.bind(this);
    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(JSON.stringify(this.props.chkNavigation) && JSON.stringify(nextProps.chkNavigation)){
      this.setState({chkNavigation: nextProps.chkNavigation})
    }
  }

  componentDidMount() {
    this.props.headerMainMenu().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.headerMainMenuSuccess(response.value.data.payload);
      }
    })
    window.addEventListener('click', (e) => {
      if (e.target.className !== 'menu-item' && this.state.activeMenu !== null) {
        this.setState({
          activeMenu: null
        });
      }
    });
    //this.mobileNavigationInitialization();
  }



  mobileNavigationInitialization = (() => {
    window.$$(document).ready(function () {
      window.$$(".first-tab").on("click", function () {
        window.$$("#indian-package").addClass("active");
        window.$$("#international-package").removeClass("active");
      })

      window.$$(".second-tab").on("click", function () {
        window.$$("#international-package").addClass("active");
        window.$$("#indian-package").removeClass("active");
      })

      window.$$(".package-heading li a").on("click", function () {
        window.$$(this).parent().addClass('active').siblings().removeClass('active');
      });


      window.$$(".mobile-navigation li.dropdown a").on("click", function () {
        window.$$(".mobile-navigation").addClass("active");
      })

      window.$$(".close-btn").on("click", function () {
        window.$$(".mobile-navigation").toggleClass("active");
        window.$$(".mobile-navigation .middle-block li").removeClass("show");
        window.$$(".mobile-navigation .middle-block li .dropdown-menu").removeClass("show");
      });

      window.$$(".msg-close-btn").on("click", function () {
        window.$$(".alert-msg").hide();
      });

      window.$$('.mobile-navigation .dropdown-menu').click(function (e) {
        e.stopPropagation();
      });


    });
  });

  componentDidUpdate(){
		// const $ = window.$$;
    // window.$$(document).ready(function () {

    //   window.$$('.nav-hamburger').on('click', function (event) {
    //     event.stopPropagation();
    //     $('body').toggleClass('nav-active')
    //     if( $('body').hasClass('nav-active')){
    //       //$('body').removeClass('nav-active');
    //     }else{
    //       //$('body').addClass('nav-active');
    //     }
        
    //   });

    // });
	}

  toggle(e, activeMenu) {
    e.preventDefault();
    this.setState({
      activeMenu
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showModal() {
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  showSignupModal() {
    window.getFooter().setState({
      renderElement: <SignupModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideCustomRequestForm(){
    window.getFooter().setState({
        renderElement: null
    });
  }

  showCustomRequestForm(){
    window.getFooter().setState({
      renderElement: <CustomizeRequestModal toggle={this.hideCustomRequestForm} />
    });
  }

  render() {

    const { auth, user, src } = this.props
    const { activeMenu } = this.state;

    return (
      <div className="mobile-navigation" id="mobile-navigation">
        <div className="top-block">

        </div>

        <div className="middle-block">
          <ul className="navbar-nav">
            {/* {auth ? <li><a href="javascript:;">My Bookings</a></li> : null} */}
            <li className="nav-item dropdown">
              <a href="javascript:;" id="menu1" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.$$("#menu11").addClass('sub-open');
                window.$$('#mobile-navigation').addClass('inner-menu-open');

              }}>Holiday Packages</a>
              <ul className="dropdown-menu" id="menu11">
                <li>
                  <div className="submenu-block">
                    <div className="submenu-heading">
                      <h3>Holiday Packages</h3>
                      <button className="close-btn" id="close-btn-menu11" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.$$("#menu11").removeClass('sub-open');
                        window.$$('#mobile-navigation').removeClass('inner-menu-open');
                      }}>
                        <span></span>
                        <span></span>
                      </button>
                    </div>

                    <div className="submenu-dropdown">
                      {/* <ul className="active" id="indian-package">
                        {
                          this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.domestic && this.state.chkNavigation.holiday_packages_menu.domestic.length > 0 &&
                          this.state.chkNavigation.holiday_packages_menu.domestic.map((obj, index) => (
                            <li>
                              <Link to={"/destinationpackage/" + obj.slug}>
                                {obj.destination_name}
                              </Link>
                            </li>
                          ))
                        }
                      </ul> */}

                      <ul className="active" id="international-package">
                        {
                          this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.international && this.state.chkNavigation.holiday_packages_menu.international.length > 0 &&
                          this.state.chkNavigation.holiday_packages_menu.international.map((obj, index) => (
                            <li key={index}>
                              <Link onClick={() => {
                                window.$$("body").removeClass('nav-active');
                              }} to={"/destinationpackage/" + obj.slug}>{obj.destination_name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>

                    <div className="package-heading">
                      <ul>
                        {/* <li className="first-tab active"><a href="javascript:;">INDIAN</a></li> */}
                        {/* <li className="second-tab active"><a href="javascript:;">INTERNATIONAL</a></li> */}
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a href="javascript:;" id="menu2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.$$("#menu22").addClass('sub-open');
                  window.$$('#mobile-navigation').addClass('inner-menu-open');

                }}>Destinations Guides</a>
              <ul className="dropdown-menu" id='menu22'>
                <li>
                  <div className="submenu-block">
                    <div className="submenu-heading">
                      <h3>Destinations Guides</h3>
                      <button className="close-btn" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.$$("#menu22").removeClass('sub-open');
                        window.$$('#mobile-navigation').removeClass('inner-menu-open');
                      }}>
                        <span></span>
                        <span></span>
                      </button>
                    </div>

                    <div className="submenu-dropdown">
                      {/* <ul className="active" id="indian-package">
                        {
                          this.state.chkNavigation.destination_guide_menu && this.state.chkNavigation.destination_guide_menu.domestic && this.state.chkNavigation.destination_guide_menu.domestic.length > 0 &&
                          this.state.chkNavigation.destination_guide_menu.domestic.map((obj, index) => (
                            <li>
                              <Link to={"/destinationguide/" + obj.slug}>{obj.destination_name}</Link>
                            </li>
                          ))
                        }
                        
                      </ul> */}

                      <ul className="active" id="international-destination-package">
                        {
                          this.state.chkNavigation.destination_guide_menu && this.state.chkNavigation.destination_guide_menu.international && this.state.chkNavigation.destination_guide_menu.international.length > 0 &&
                          this.state.chkNavigation.destination_guide_menu.international.map((obj, index) => (
                            <li key={index}>
                              <Link onClick={() => {
                                window.$$("body").removeClass('nav-active');
                              }} to={"/destinationguide/" + obj.slug}>{obj.destination_name}</Link>
                            </li>
                          ))
                        }

                      </ul>
                    </div>

                    <div className="package-heading">
                      <ul>
                        {/* <li className="first-tab active"><a href="javascript:;">INDIAN</a></li> */}
                        {/* <li className="second-tab"><a href="javascript:;">INTERNATIONAL</a></li> */}
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            {/* <li className="nav-item dropdown"><a href="javascript:;" id="menu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Themes</a>
              <ul className="dropdown-menu" aria-labelledby="menu3">
                <li>
                  <div className="submenu-block theme-package">
                    <div className="submenu-heading">
                      <h3>Themes Packages</h3>
                      <button className="close-btn">
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                    <div className="theme-listing">
                      <ul>
                        {
                          this.state.chkNavigation.theme_menu && this.state.chkNavigation.theme_menu.length > 0 &&
                          this.state.chkNavigation.theme_menu.map((obj, index) => (
                            <li key={index}>
                              <Link to={"/theme/" + obj.slug}>
                                <span className={"theme-icons t-icon" + parseInt(index + 1)}></span>
                                {obj.title}
                              </Link>
                            </li>
                          ))
                        }
                        
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </li> */}

            {
              auth ?
                <>
                  <li><Link to={"/notifications"}>Notifications</Link></li>
                  <li><Link to="/logout">Logout</Link></li>
                </> : null
            }
          </ul>
        </div>
        <div className="bottom-block"><a href="javascript::" className="btn-1" onClick={this.showCustomRequestForm}>Plan My Holidays</a></div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    auth: state.Auth.auth,
    user: { ...state.Auth.user },
    src: state.Auth.user.profile_image,
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status,
    nav: state.navigation
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    headerMainMenu: () => {
      return dispatch(headerMainMenu());
    },
    headerMainMenuSuccess: (payload) => {
      dispatch(headerMainMenuSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(MobileNavigation);