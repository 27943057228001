import React, { Component } from 'react';

export default class WhyTravelAtlas extends Component {
   	render() {
   		return(
   			<section className="total-destination-block">
	            <div className="container">
	               <div className="row">
	                  <div className="col-sm-12 text-center">
	                      <ul>
	                          <li><span>1000+</span> Travellers </li>
	                          <li><span>100+</span> Destinations </li>
	                          <li><span>20+</span> Travel Experts </li>
	                      </ul>
	                  </div>
	               </div>
	            </div>
	        </section>
   		);
   	}
}