import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import ThemeMobileFilterBlock from './components/partials/ThemeMobileFilterBlock';
import InnerBannerBlock from './components/common/InnerBannerBlock';
import ThemeMainContentBlock from './components/theme/ThemeMainContentBlock';
import { connect } from 'react-redux';
import Loader from './components/common/loader';
import { holidayTypeListSummary, holidayTypeListSummarySuccess, resetFilters } from './actions/holidayType';
import { travelPackageList } from './actions/packages';

const queryString = require('query-string');

class ThemesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeDetail: null,
      status: props.status,
      packages: []
    };
  }

  // componentWillMount() {
  //   this.props.travelPackageList({ categories: [this.props.match.params.index] }).then((response) => {
  //     if (response.value.data.result === 'success') {
  //       this.setState({ packages: response.value.data.payload.data});
  //     }
  //   });
  //   let filters = { slug: this.props.match.params.index };
  //   if (this.props.location.search != '') {
  //     filters = { ...filters, ...queryString.parse(this.props.location.search, { arrayFormat: 'bracket' }) };
  //     this.props.resetFilters(filters);
  //   }
  //   this.props.holidayTypeListSummary(filters).then((response) => {
  //     if (response.value.data.result === 'success') {
  //       this.setState({ themeDetail: response.value.data.payload });
  //     }
  //   });
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.compName == 'filters' && nextProps.location === this.props.location) {
  //     this.props.history.push({
  //       pathname: nextProps.location.pathname,
  //       search: "?" + window.$$.param(nextProps.filters)
  //     });
  //   } else if (this.props.match.params.index != nextProps.match.params.index) {
  //     this.props.resetFilters({ slug: nextProps.match.params.index }, false);
  //     this.props.holidayTypeListSummary({ slug: nextProps.match.params.index }).then((response) => {
  //       if (response.value.data.result === 'success') {
  //         this.setState({
  //           themeDetail: response.value.data.payload
  //         });
  //       }
  //     });
  //   }
  // }
  componentWillMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500);
    let filters = {};
    if (this.props.location.search != '') {
      filters = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });
      this.props.resetFilters(filters, false);
    }
  }

  // Update URL query string with filter change
  componentWillReceiveProps(nextProps) {
    if (this.state.onMount) {
      if (nextProps.compName == 'filters' && this.state.status != nextProps.status && nextProps.location === this.props.location) {
        this.setState({
          status: nextProps.status
        });
        this.props.history.push({
          pathname: nextProps.location.pathname,
          search: "?" + window.$$.param(nextProps.filters)
        });
      }
    } else {
      this.setState({
        onMount: true
      })
    }
  }

  onOpenFilter() {
    let element = document.getElementById("filterToShow");
    ReactDOM.findDOMNode(element).classList.add("activefilter");
  }

  render() {
    const { themeDetail } = this.state;
    return (
      <Fragment>
        {
          themeDetail === null ? <Loader /> :
            <div className="main-body-content">
              {/* <ThemeMobileFilterBlock /> */}

              <section className="mf-block">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="filter-listing">
                        <li onClick={this.onOpenFilter}>Filters</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <InnerBannerBlock title={this.state.themeDetail.title} image={this.state.themeDetail.image} sub_title={this.state.themeDetail.sub_title} detail={this.state.themeDetail.detail} />
              <ThemeMainContentBlock themeType={this.state.themeDetail.title} themePackages={this.state.themeDetail.packages} />
              
            </div>
        }
      </Fragment>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.holidayTypes.status,
    compName: state.holidayTypes.compName,
    filters: { ...state.holidayTypes.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    holidayTypeListSummary: (params) => {
      return dispatch(holidayTypeListSummary(params));
    },
    holidayTypeListSummarySuccess: (payload) => {
      dispatch(holidayTypeListSummarySuccess(payload));
    },
    resetFilters: (filters, slugRequired) => {
      dispatch(resetFilters(filters, slugRequired));
    },
    travelPackageList: (payload) => {
      return dispatch(travelPackageList(payload));
    },
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ThemesList);