import React, { Component } from "react";
import { connect } from "react-redux";
import Error from "../../common/Error";
import AllDestinations from "../../common/AllDestinations";
import { Dropdown } from "react-bootstrap";
import {
  saveContactForm,
  fetchContactFromStep,
} from "../../../actions/customPackage";
import DateMonthPicker from "../../common/DateMonthPicker";
import DateRangePicker from "../../common/DateRangePicker";
import axios, { post } from "axios";
import { API_ROOT } from "../../../constants";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      emailId: "",
      contactNumber: "",
      destination: "",
      numberOfPax: "",
      dateOfTravel: "",
      message: "",
      countryCode: "+91",
      errors: {
        name: "",
        emailId: "",
        contactNumber: "",
        destination: "",
        numberOfPax: "",
        dateOfTravel: "",
        message: "",
      },
      submitted: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  handleDateChange(selectedDate) {
    console.log(`selectedDate`, selectedDate);
    this.setState({ dateOfTravel: selectedDate });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  validateForm() {
    const {
      name,
      emailId,
      contactNumber,
      destination,
      numberOfPax,
      dateOfTravel,
      message,
    } = this.state;
    let errors = {};
    let isValid = true;

    if (!name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!emailId) {
      errors.emailId = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(emailId)) {
      errors.emailId = "Email is invalid";
      isValid = false;
    }

    if (!contactNumber) {
      errors.contactNumber = "Contact number is required";
      isValid = false;
    }
    // else if (!/^\d{5}-\d{5}$/.test(contactNumber)) {
    //   errors.contactNumber = "Contact number is invalid (e.g., 12345-67890)";
    //   isValid = false;
    // }

    if (!destination) {
      errors.destination = "Destination is required";
      isValid = false;
    }

    if (!numberOfPax) {
      errors.numberOfPax = "Number of Pax is required";
      isValid = false;
    } else if (isNaN(numberOfPax) || numberOfPax <= 0) {
      errors.numberOfPax = "Number of Pax must be a positive number";
      isValid = false;
    }
    if (!dateOfTravel) {
      errors.dateOfTravel = "Date of travel is required";
      isValid = false;
    }

    // if (!message) {
    //   errors.message = "Message is required";
    //   isValid = false;
    // }
    this.setState({ errors });
    return isValid;
  }

  submitForm(e) {
    e.preventDefault();
    if (!this.validateForm()) {
      return;
    }
    const {
      name,
      emailId,
      contactNumber,
      destination,
      numberOfPax,
      country_code,
      dateOfTravel,
      message,
      errors,
    } = this.state;
    this.setState({ submitted: true });
    const data = {
      name,
      emailId,
      contactNumber,
      destination,
      numberOfPax,
      dateOfTravel,
      message,
    };
    const request = axios({
      method: "POST",
      data,
      url: `${API_ROOT}/contactuspopup`,
    })
      .then((response) => {
        localStorage.setItem("contactFromSubmit", true);
      })
      .catch((error) => console.log(error, "eree2"));
    // this.props.saveContactForm();
  }
  handleCancel() {
    const timestamp = new Date().toISOString();
    localStorage.setItem("cancelTimestamp", timestamp);
  }
  render() {
    const {
      name,
      emailId,
      contactNumber,
      destination,
      numberOfPax,
      countryCode,
      dateOfTravel,
      message,
      errors,
      submitted,
    } = this.state;
    if (submitted) {
      return (
        <div
          className="customize-right-block fourth-step"
          style={{ display: "inline-block" }}
        >
          <div className="customize-form-block">
            <div className="row">
              <div className="col-sm-12">
                <div className="success-content">
                  <ion-icon
                    ios="ios-checkmark-circle"
                    md="ios-checkmark-circle-outline"
                  />
                  <h3>Success</h3>
                  <p>
                    {" "}
                    Check your email for a booking confirmation. we'll <br />
                    see you soon!.
                  </p>
                  <a
                    href="javascript: void(0);"
                    className="cta-btn cncl-btn"
                    data-dismiss="modal"
                  >
                    {" "}
                    ok{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // const countryCodes = ["+91", "+01"];
    return (
      <div className="customize-right-block first-step">
        <div className="right-top-block">
          <h3>Drop your Requirements</h3>
          <button
            type="button"
            className="close cncl-btn"
            data-dismiss="modal"
            onClick={this.handleCancel}
          >
            CLOSE
          </button>
        </div>

        <div className="customize-form-block contactForm">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  ref="name"
                  type="text"
                  name="name"
                  defaultValue={name}
                  onChange={this.handleChange}
                  placeholder="your name"
                />
                {typeof errors.name !== "undefined" && (
                  <Error text={errors.name} />
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  name="emailId"
                  type="text"
                  placeholder="Your Email"
                  onChange={this.handleChange}
                  defaultValue={emailId}
                />
                {typeof errors.emailId !== "undefined" && (
                  <Error text={errors.emailId} />
                )}
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <div className="number-field" style={{ display: "flex" }}>
                  <input
                    defaultValue={countryCode}
                    onChange={this.handleChange}
                    name="countryCode"
                    type="tel"
                    className="selectpicker"
                    style={{ width: "100px", textAlign: "center" }}
                  />

                  <div className="pn-field pn-field-contact">
                    <input
                      defaultValue={contactNumber}
                      onChange={this.handleChange}
                      ref="contactNumber"
                      name="contactNumber"
                      type="tel"
                      className="form-control"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                {typeof errors.country_code !== "undefined" && (
                  <Error text={errors.country_code} />
                )}
                {typeof errors.contactNumber !== "undefined" && (
                  <Error text={errors.contactNumber} />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  name="destination"
                  type="text"
                  placeholder="Enter your Destination"
                  onChange={this.handleChange}
                  defaultValue={destination}
                />

                {typeof errors.destination !== "undefined" && (
                  <Error text={errors.destination} />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  name="numberOfPax"
                  defaultValue={numberOfPax}
                  onChange={this.handleChange}
                  placeholder="Traveller Count"
                  //   min="1"
                />
                {typeof errors.name !== "undefined" && (
                  <Error text={errors.numberOfPax} />
                )}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <div className="custom-datepicker">
                  <DateRangePicker
                    selectedValue={dateOfTravel}
                    onHandleChange={this.handleDateChange}
                    single={true}
                    name="dateOfTravel"
                    fromContactFrom={true}
                  />
                </div>
                {/* <input
                  type="text"
                  name="dateOfTravel"
                  value={dateOfTravel}
                  onChange={this.handleChange}
                  placeholder="Choose Date of Travel"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                /> */}
                {typeof errors.dateOfTravel !== "undefined" && (
                  <Error text={errors.dateOfTravel} />
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <textarea
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                  placeholder="Message"
                />
                {typeof errors.message !== "undefined" && (
                  <Error text={errors.message} />
                )}
              </div>
            </div>
            <div className="col-sm-12 secure-content secureContentContact">
              <p>We ensure the privacy of your contact data</p>
              <p>
                This data will only be used by our team to contact you and no
                other purpose
              </p>
            </div>
            <div className="contactFromButton">
              {" "}
              <button
                type="button"
                className="close cncl-btn skip-btn"
                data-dismiss="modal"
                onClick={this.handleCancel}
                style={{ paddingRight: "45px" }}
              >
                SKIP
              </button>
              <button
                className="cta-btn step1 contactFromSubmitBtn"
                type="submit"
                onClick={this.submitForm}
                style={{ minWidth: "200px" }}
              >
                Request Call Back
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const data = typeof ownProps.data !== "undefined" ? ownProps.data : {};
  console.log("DATAA", data);
  return {
    data: { ...state.CustomPackage.conectFromStep },
    quote_id: state.CustomPackage.firstStep.quote_id,
    status: state.CustomPackage.status,
    compName: state.CustomPackage.compName,
    errors: { ...state.CustomPackage.errors },
  };
};

const mapDispatchToProps = {
  //   saveContactForm,
};

export default connect(
  mapStateToProps,
  { saveContactForm, fetchContactFromStep }
)(ContactForm);
