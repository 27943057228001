import React, { Component } from 'react';
import WeekendGatewaySlide from './WeekendGatewaySlide';
import { Link } from 'react-router-dom';
import {ROUTES} from '../../routesList';
import { connect } from 'react-redux';
import { weekendPackagesList, weekendPackagesListSuccess } from '../../actions/packages';

class WeekendGateways extends Component {
	constructor(props){
	    super(props);
	    this.bindSlider = this.bindSlider.bind(this);
	    this.state = {
	      	weekendPackages: props.weekendPackages,
	    	status: props.status
	    }
	}

	componentDidMount(){
	    this.props.weekendPackagesList().then((response) => {
	      if(response.value.data.result === 'success'){
	        this.props.weekendPackagesListSuccess(response.value.data.payload);
	      }
	    })

	    window.addEventListener('load', () => {
	      this.bindSlider();
	    });

	    if(document.readyState === "complete" || document.readyState === "interactive"){
	      this.bindSlider();
	    }
	}

	componentWillReceiveProps(nextProps){
	    if(this.props.status != nextProps.status){
	      this.setState({
	        weekendPackages: nextProps.weekendPackages,
	        status: nextProps.status
	      })
	    }
	}

	bindSlider(){
	    const $ = window.$$;
	    if($(window).width() > 991){
			$('.h-slider2').owlCarousel({
			  	loop:false,
			  	margin:15,
			 	pagination: false,
			  	nav:true,
			  	navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
			  	responsive:{
			      	0:{
			      		items:1.2
			      	},
			    	450:{
			          	items:2
			      	},
			      	575:{
			        	items:2
			      	},
			      	576:{
			        	items:1
			      	},
			      	767:{
			          	items:1
			      	},
			      	768:{
			        	items:2
			     	},
			      	992:{
			          	items:3
			      	}
			  	}
			})

	    }
	}

   	render() {
   		return(
			this.state.weekendPackages && this.state.weekendPackages.length > 0
			?
   			<section className="h-package-block weekend-gateway-block">
	            <div className="container">
	               	<div className="row">
	                  	<div className="col-lg-3 col-md-4 col-sm-5">
	                    	<div className="add-banner">
	                        	<Link to="/travel-packages?duration=1-3">
	                           		<figure><img src="/images/weekend.png" alt=""/></figure>
	                           		<figcaption className="add-banner-content">
	                              		<label>EXPLORE</label>
	                              		<h4>Weekend Getaways</h4>
	                              		<button className="n-blue-btn"> VIEW ALL</button>
	                           		</figcaption>
	                        	</Link>
	                     	</div>
	                  	</div>
	                  	{/*  weekend gateway slide */}
	                  	<div className="col-lg-9 col-md-8 col-sm-7">
			             	<div className="owl-carousel owl-theme h-slider2">
			             		{
						          this.state.weekendPackages && this.state.weekendPackages.length > 0 &&
						          this.state.weekendPackages.map((obj, index) => (
						            <WeekendGatewaySlide key={index} slideData = {obj} />
						          ))
						        }
			             	</div>
			          	</div>
	               	</div>
	            </div>
	        </section>
			:
			null
   		);
   	}
}

const mapStatesToProps = (state, ownProps) => {
  	return {
    	weekendPackages: [...state.packages.weekendpackages],
    	status: state.packages.status
  	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    weekendPackagesList: (params) => {
      return dispatch(weekendPackagesList(params));
    },
    weekendPackagesListSuccess: (payload) => {
      dispatch(weekendPackagesListSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(WeekendGateways);