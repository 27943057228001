import React, { Component, Fragment } from 'react';
import DestinationGuideThingsTodoSlide from './DestinationGuideThingsTodoSlide';
import { destinationGuideThingsToDo } from '../../actions/destinationGuide';
import { connect } from 'react-redux';

class DestinationGuideThingsTodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thingsToDo: null,
      id: props.id
    };
    this.bindSlider = this.bindSlider.bind(this);
  }

  componentDidMount() {
    this.fetchRecords(this.state.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id != this.state.id) {
      this.fetchRecords(nextProps.id);
    }
  }

  componentDidUpdate() {
    window.addEventListener('load', this.bindSlider);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  fetchRecords(id) {
    this.props.destinationGuideThingsToDo({ destination_id: id }).then((response) => {
      if (response.value.data.result === 'success') {
        // console.log(response, "response");
        this.setState({
          thingsToDo: response.value.data.payload,
        });
      }
    });
  }



  bindSlider() {
    const $ = window.$$;
    if ($(window).width() > 991) {
      $(this.refs.packagesSlider).owlCarousel({
        loop: false,
        margin: 15,
        pagination: false,
        nav: true,
        navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
        responsive: {
          0: {
            items: 1.2
          },
          400: {
            items: 1.4
          },
          500: {
            items: 2.2
          },
          768: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      });
    }
  }

  render() {
    return (
      <section className="things-do" id="things-do">
        {
          this.state.thingsToDo && this.state.thingsToDo.length > 0 ?
            <Fragment>
              <div className="container">
                <div className="sm-heading-main">
                  <h4>Things to do in n {this.props.destination}</h4>
                  <p>Your list of the best things to do, compiled on the basis of actual traveler experiences</p>
                </div>
              </div>
              <div className="slider-popular slider-full">
                <div className="container">
                  <div className="owl-carousel owl-theme popular-slider-theme" ref="packagesSlider">
                    {
                      this.state.thingsToDo && this.state.thingsToDo.length > 0 && this.state.thingsToDo.map(data => {
                        return <DestinationGuideThingsTodoSlide data={data} />
                      })
                    }
                  </div>
                </div>
              </div>
            </Fragment>
            : null
        }
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationGuideThingsToDo: (params) => {
      return dispatch(destinationGuideThingsToDo(params));
    }
  };
}

export default connect(null, mapDispatchToProps)(DestinationGuideThingsTodo);