import React, { Component } from 'react';
import PackageTypeFilters from '../package/PackageTypeFilters';
import ThemeDestinationMainBlock from '../destination/ThemeDestinationMainBlock';
import TravelPackageSection from '../package/TravelPackageSection';
import PackageFilters from '../package/PackageFilters';

export default class ThemeMainContentBlock extends Component {
  render() {
    return (
      <section className="main-content-block">
        <div className="container">
          <div className="row">
            <div id="filterToShow" className="col-xl-3 col-lg-12 col-md-12 col-sm-12 sidebar-outer ">
              <div className="wrap"></div>
              {/* all left side filters */}
              {/* <PackageTypeFilters /> */}
              <PackageFilters />
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
              {/* <ThemeDestinationMainBlock themeType={this.props.themeType} themePackages={this.props.themePackages} /> */}
              <TravelPackageSection />
            </div>
          </div>
        </div>
      </section>
    );
  }
}